import React,{useState,useEffect} from 'react'
import { BASE_URL,VIEWSFEEDBACK } from '../utils/ApplicationEndpoints';
import axios from 'axios';
import { ArrowForward } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

function ViewsFeedback(props) {
  const [data, setData] = useState([])

  const [showMoreArray, setShowMoreArray] = useState(Array(props.length).fill(false));
  const location = useLocation();

  const handleToggle = (index) => {
    const updatedShowMoreArray = [...showMoreArray];
    updatedShowMoreArray[index] = !updatedShowMoreArray[index];
    setShowMoreArray(updatedShowMoreArray);
  };

  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
   axios.get(BASE_URL+VIEWSFEEDBACK).then(
      res=>{
        if(res.status===200){
          setData(res.data)
          // setRating(res.data.rating)
        }
       
  
      }
    ).catch(err=>{
      console.log(err);
    });
  
    
  }, [])

  const  isUsRoute = location.pathname.startsWith('/us');
const isInRoute=location.pathname.startsWith('/in');

  return (
    <div>
      {props.limit ? 
         <section id="testimonials" className="ud-testimonials">
         <div className="container">
            <div className="row">
            {Array.isArray(data) ? (
          data.slice(0, 3).map((testimonial, index) => (
             <div key={index} className="col-lg-4 col-md-6">
               <div className="ud-single-testimonial wow fadeInUp" data-wow-delay=".1s">
                 <div className="ud-testimonial-ratings">
                   {[...Array(testimonial.rating)].map((_, index) => (
                     <i key={index} className="lni lni-star-filled"></i>
                   ))}
                 </div>
                 <div className="ud-testimonial-content">
              <p>
                {testimonial.feedBack.length > 50 && !showMoreArray[index] ? (
                  <>
                    {testimonial.feedBack.slice(0, 50)}... &nbsp;
                    <span onClick={() => handleToggle(index)} className="show-more-link">
                      {'Show More'}
                    </span>
                  </>
                ) : (
                  <>
                    {testimonial.feedBack}
                    
                    {testimonial.feedBack.length > 50 && (
                      <span onClick={() => handleToggle(index)} className="show-more-link">
                        {' '}
                        Less
                      </span>
                    )}
                  </>
                )}
              </p>
            </div>
                 <div className="ud-testimonial-info">
                   <div className="ud-testimonial-image">
                     <img src="assets/images/testimonials/author-01.png" title='author image' alt="author" loading='eager'  width={'actualWidth'} height={'actualHeight'}/>
                   </div>
                   <div className="ud-testimonial-meta">
                     {/* <h3>{testimonial.name}</h3> */}
                       {/* modified by mohan raj for HB-XXXX on 29-08-23 start */}

                     <h3 style={{ color: '#363636' }}>{testimonial.name}</h3>
                       {/* modified by mohan raj for HB-XXXX on 29-08-23 end */}

                     <p>{testimonial.designation}</p>
                     
                     
                   </div>
                 </div>
               </div>
             </div>
           ))
         ) : (
           <div className="col-lg-4 col-md-6">
             <div className="ud-single-testimonial wow fadeInUp" data-wow-delay=".1s">
               <div className="ud-testimonial-ratings">
                 {[...Array(data.rating)].map((_, index) => (
                   <i key={index} className="lni lni-star-filled"></i>
                 ))}
               </div>
               <div className="ud-testimonial-content">
                 <p>{data.feedBack}</p>
                 
               </div>
               <div className="ud-testimonial-info">
                 <div className="ud-testimonial-image">
                   <img src="assets/images/testimonials/author-01.png" title='author image' alt="author" loading='eager'  width={'actualWidth'} height={'actualHeight'} />
                 </div>
                 <div className="ud-testimonial-meta">
                   <h3>{data.name}</h3>
                   <p>{data.designation}</p>

                   
                   
                 </div>
               </div>
             </div>
           </div>
         )}
     
     
   
           </div>
           {props.readmore ? 
            <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2" style={{textAlign:'center'}}>
             <div >
             <Link to={'/vfbdetails'} className='ud-about-content'>Read More <ArrowForward/> </Link>
               </div>
           
          </div> : null}
           </div>
       </section> 
       :
       <section id="testimonials" className="ud-testimonials">
       <div className="container">
          <div className="row">
          {Array.isArray(data) ? (
        data.map((testimonial, index) => (
           <div key={index} className="col-lg-4 col-md-6">
             <div className="ud-single-testimonial wow fadeInUp" data-wow-delay=".1s">
               <div className="ud-testimonial-ratings">
                 {[...Array(testimonial.rating)].map((_, index) => (
                   <i key={index} className="lni lni-star-filled"></i>
                 ))}
               </div>
               <div className="ud-testimonial-content">
              <p>
                {testimonial.feedBack.length > 50 && !showMoreArray[index] ? (
                  <>
                    {testimonial.feedBack.slice(0, 50)}... &nbsp;
                    <span onClick={() => handleToggle(index)} className="show-more-link">
                      {'Show More'}
                    </span>
                  </>
                ) : (
                  <>
                    {testimonial.feedBack}
                    {testimonial.feedBack.length > 50 && (
                      <span onClick={() => handleToggle(index)} className="show-more-link">
                        {' '}
                        Less
                      </span>
                    )}
                  </>
                )}
              </p>
            </div>
               <div className="ud-testimonial-info">
                 <div className="ud-testimonial-image">
                   <img src="assets/images/testimonials/author-01.png" alt="author" title='author' loading='eager'  width={'actualWidth'} height={'actualHeight'}/>
                 </div>
                 <div className="ud-testimonial-meta">
                   <h4>{testimonial.name}</h4>
                   <p>{testimonial.designation}</p>
                 </div>
               </div>
             </div>
           </div>
         ))
       ) : (
         <div className="col-lg-4 col-md-6">
           <div className="ud-single-testimonial wow fadeInUp" data-wow-delay=".1s">
             <div className="ud-testimonial-ratings">
               {[...Array(data.rating)].map((_, index) => (
                 <i key={index} className="lni lni-star-filled"></i>
               ))}
             </div>
             <div className="ud-testimonial-content">
               <p>{data.feedBack}</p>
             </div>
             <div className="ud-testimonial-info">
               <div className="ud-testimonial-image">
                 <img src="assets/images/testimonials/author-01.png"  title='author image' alt="author" loading='eager'  width={'actualWidth'} height={'actualHeight'}/>
               </div>
               <div className="ud-testimonial-meta">
                 <h4>{data.name}</h4>
                 <p>{data.designation}</p>
               </div>
             </div>
           </div>
         </div>
       )}
  
   
 
         </div>
         {props.readmore ? 
          <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2" style={{textAlign:'center'}}>
           <div >
           <Link to={ '/vfbdetails'} className='ud-about-content'>Read More <ArrowForward/> </Link>
             </div>
         
        </div> : null}
         </div>
     </section>}
   
    </div>
  )
}

export default ViewsFeedback
