import React from "react";
import Header from "../Components/Header";
import PageHeader from "../Components/PageHeader";
import HomeFaq from "../Components/HomeFaq";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";

function Faq() {
  return (
    <div>
      <Header />
      <Helmet>
        <title>FAQ</title>
        <meta name="title" content="FAQ" />
        <meta
          name="description"
          content="Get answers to common questions about HOA management, fees, rules, and more. Expert solutions for a well-informed community"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hybridhoa.com/faqdetail" />
        <meta property="og:title" content="FAQ" />
        <meta
          property="og:description"
          content="Get answers to common questions about HOA management, fees, rules, and more. Expert solutions for a well-informed community"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/faqdetail"
        />
        <meta property="twitter:title" content="FAQ" />
        <meta
          property="twitter:description"
          content="Get answers to common questions about HOA management, fees, rules, and more. Expert solutions for a well-informed community"
        />
        <meta property="og:image" content="../assets/img/preview.png" />
      </Helmet>
      <PageHeader />
      <HomeFaq />
      <Footer />
    </div>
  );
}

export default Faq;
