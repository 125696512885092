import React from "react";

import Hero from "../Components/Hero";
import Footer from "../Components/Footer";
import HomeContact from "../Components/HomeContact";
import ViewsFeedback from "../Components/ViewsFeedback";

import { Routes, Route, useLocation } from "react-router-dom";
import HomePricing from "../Components/HomePricing";
import HomeAbout from "../Components/HomeAbout";
import HomeFeature from "../Components/HomeFeature";
import HomeFaq from "../Components/HomeFaq";
import Start from "../Components/Start";
import HomeFeatureMain from "../Components/HomeFeatureMain";
import Feedback from "../Components/Feedback";
import PricingHeader from "../Components/PricingHeader";
import { Helmet } from "react-helmet";
import HomePagePricing from "./HomePagePricing";
import HomePagePricingIn from "./HomePagePricingIn";
import FeaturesHome from "./FeaturesHome";

function HomePage() {
  const location = useLocation();

  const isPathIn = location.pathname.startsWith("/in");
  const isPathUs = location.pathname.startsWith("/us");

  console.log("isPathUs:", isPathUs);
  console.log("isPathIn:", isPathIn);

  return (
    <div>
      <Helmet>
        <title>HybridHOA</title>
        <meta
          name="title"
          content="HybridHOA: Property Management Software for HOA"
        />
        <meta
          name="description"
          content="HybridHOA is an all-in-one solution for homeowners association & property management, simplifying community management"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hybridhoa.com/" />
        <meta property="og:title" content="HybridHOA" />
        <meta
          property="og:description"
          content="HybridHOA is an all-in-one solution for homeowners association & property management, simplifying community management"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.hybridhoa.com/" />
        <meta property="twitter:title" content="HybridHOA" />
        <meta
          property="twitter:description"
          content="HybridHOA is an all-in-one solution for homeowners association & property management, simplifying community management"
        />
        <meta property="og:image" content="../assets/img/preview.png" />
        <link rel="canonical" href="https://hybridhoa.com/" />
        <link rel="icon" href="%PUBLIC_URL%/hybridhoa-512x512.png" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
        ></meta>
      </Helmet>
      {/* <Header /> */}
      <Hero />
      <HomeAbout title="About Us" readmore="true" />
      <HomeFeatureMain title="Features" />
      {/* <HomeFeature title="Features" readmore="true" limit="true" /> */}
      <FeaturesHome readmore="true" limit="true" />

      {/* <HomePricing title="Pricing" readmore="true" /> */}
      <HomePagePricing title="HomePagePricing" readmore="true" />
      {/* {isPathIn && (<HomePagePricingIn title="HomePagePricing" readmore="true" />)} */}

      {/* <div className='container'>

      <PricingHeader/>
      </div> */}
      <HomeFaq title="" readmore="true" limit="true" />

      <HomeContact />
      <Feedback />
      <ViewsFeedback title="" readmore="true" limit="true" />
      {/* <Start/> */}
      {/* <Footer/> */}
      {/* <Blog/>
      <Singlepricing/>
      <FeaturesSingle/> */}

      {/* <Footer/> */}
      <Footer />
    </div>
  );
}

export default HomePage;
