import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import axios from "axios";
import {
  BASE_URL,
  FEATURE_NOTIFICATIONS,
  HERO,
  IMAGE,
} from "../utils/ApplicationEndpoints";
import { Box, Button, DialogTitle, Modal } from "@mui/material";

import BackgroundImage from "../assets/img/backgroundImg.png";
import { Close } from "@mui/icons-material";

function Hero() {
  const [featureResponse, setfeatureResponse] = useState([]);
  const [desc, setDesc] = useState("");

  const [imageData, setImageData] = useState("");
  const [alt, setAlt] = useState("");
  const HeroList = () => {
    axios
      .get(BASE_URL + HERO)
      .then((res) => {
        setDesc(res.data[0].description);

        setImageData(res.data[0].imageUrl);
        setAlt(res.data[0].imgAlternative);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    height: 400,

    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  };

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    HeroList();
    handleClickOpen();
  }, []);

  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [show, setShow] = useState(false);

  const FeatureInformationList = () => {
    axios
      .get(BASE_URL + FEATURE_NOTIFICATIONS)
      .then((res) => {
        setfeatureResponse(res.data);
        if (res.data.length !== 0) {
          setShow(true);
        }
        const randomIndex = Math.floor(Math.random() * res.data.length);
        setCurrentFeatureIndex(randomIndex);

        console.log(
          "Testttttttttttttttttttttttttttttttttttttttttttttt" +
            JSON.stringify(res.data)
        );
        console.log(
          "Testttttttttttttttttttttttttttttttttttttttttttttt" + res.data.length
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    FeatureInformationList();
  }, []);
  console.log(process.env);
  return (
    <section className="ud-hero" id="home">
      {show ? (
        <div>
          {/* <BootstrapDialog
         onClose={handleClose}
         aria-labelledby="customized-dialog-title"
         open={open}
         fullWidth
       >
      
         <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
         {featureResponse[currentFeatureIndex]?.topic}
         </BootstrapDialogTitle>
         <DialogContent dividers>
         <Typography gutterBottom>
        {featureResponse[currentFeatureIndex]?.description }
             </Typography>
            
         </DialogContent>
       
       </BootstrapDialog> */}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "white",
                }}
              >
                <Close />
              </IconButton>
              <div
                style={{
                  textAlign: "center",
                  paddingTop: 40,
                  paddingLeft: 30,
                  paddingRight: 30,
                }}
              >
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  style={{
                    textAlign: "center",
                    paddingTop: 40,
                    color: "white",
                  }}
                >
                  {featureResponse[currentFeatureIndex]?.topic}
                </Typography>
                <Typography
                  id="modal-modal-description"
                  style={{
                    textAlign: "center",
                    paddingTop: 40,
                    color: "white",
                    fontSize: 15,
                  }}
                >
                  {featureResponse[currentFeatureIndex]?.description}
                </Typography>
                <div style={{ textAlign: "center", paddingTop: 40 }}>
                  <Button
                    variant="outlined"
                    style={{ color: "black", backgroundColor: "white" }}
                  >
                    Schedule a Demoo
                    {process.env}
                  </Button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      ) : null}

      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-hero-content wow fadeInUp" data-wow-delay=".2s">
              {/* <h1 className="ud-hero-title">{topic}</h1> */}
              <p className="ud-hero-desc" style={{ textAlign: "justify" }}>
                {" "}
                {desc}
              </p>
              {/* <ul className="ud-hero-buttons">
                <li>
                  <a href={button} rel="nofollow noopener" target="_blank" className="ud-main-btn ud-white-btn" style={{ backgroundColor: 'white', color: '#2748b4', fontSize: '20px' }}>
                    Schedule Demo
                  </a>
                </li>
              </ul> */}
            </div>
            <div
              className="ud-hero-brands-wrapper wow fadeInUp"
              data-wow-delay=".3s"
            ></div>
            <div className="ud-hero-image wow fadeInUp" data-wow-delay=".25s">
              <img
                src={BASE_URL + IMAGE + `${imageData}`}
                alt={alt}
                title={alt}
                loading="eager"
                width={"100%"}
                height={"100%"}
                style={{ borderRadius: "10px" }}
              />
              <img
                src="assets/images/hero/dotted-shape.svg"
                alt="shape"
                className="shape shape-1"
                title="shape"
                loading="eager"
                width={"actualWidth"}
                height={"actualHeight"}
              />
              <img
                src="assets/images/hero/dotted-shape.svg"
                alt="shape"
                className="shape shape-2"
                title="shape"
                loading="eager"
                width={"actualWidth"}
                height={"actualHeight"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
