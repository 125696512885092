import React, { useCallback, useEffect, useState } from 'react'
import Header from '../Components/Header'
import SeparatePageHeader from '../Components/SeparatePageHeader'
import { BASE_URL, TERMS_CONDITIONS } from '../utils/ApplicationEndpoints';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import Footer from '../Components/Footer';

function TermsandConditions() {
  const [termsCondition,setTermsCondition]=useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + TERMS_CONDITIONS);
      setTermsCondition(response.data[0].data);
     
      console.log("testtttttttaishuuuuuuuuuu"+response.data[0].data);
    } catch (error) {
      console.error('Error fetching Privacy Policy data:', error);
    }
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname.startsWith('/')) {
      fetchData(0);
    } else if (pathname.startsWith('/in')) {
      fetchData(1);
    }else{
      fetchData(0);
    }
    // You can add more conditions for different URLs if needed.
  }, [fetchData]);

  const location = useLocation();

  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Helmet>
 
<title>Terms And Conditions</title>
<meta name="title" content="Terms And Conditions" />
<meta name="description" content="By using HybridHOA website, you accept and agree to these Terms. If you disagree, please refrain from using the site. Read Terms&Conditions carefully." />


<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.hybridhoa.com/termsandconditions" />
<meta property="og:title" content="Terms And Conditions" />
<meta property="og:description" content="By using HybridHOA website, you accept and agree to these Terms. If you disagree, please refrain from using the site. Read Terms&Conditions carefully." />

<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.hybridhoa.com/termsandconditions" />
<meta property="twitter:title" content="Terms And Conditions" />
<meta property="twitter:description" content="By using HybridHOA website, you accept and agree to these Terms. If you disagree, please refrain from using the site. Read Terms&Conditions carefully." />
<meta property="og:image" content="../assets/img/preview.png" />

        {/* modified by mohanraj 25-08-23 start */}
<link rel='canonical' href='https://www.hybridhoa.com/termsandconditions' />
        {/* modified by mohanraj 25-08-23 end */}
      </Helmet>
    <Header/>
      <SeparatePageHeader title="Terms & conditions"/>
     <div className='row'>
      <div className='col-lg-6 col-md-6 col-sm-6'></div>
      <div className='col-lg-6 col-md-6 col-sm-6'>
        <div dangerouslySetInnerHTML={{ __html: termsCondition }}>

        </div>
      {/* {striptags(termsCondition.toString())} */}
        </div>
        </div>
        <Footer />
    </div>
  )
}

export default TermsandConditions
