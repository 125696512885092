import React from 'react'
import Header from '../Components/Header'
import PageHeader from '../Components/PageHeader'
import { Helmet } from "react-helmet";
import Footer from '../Components/Footer';


function HybridRenty() {
  return (
    <div>
              {/* modified by mohanraj 25-08-23 start */}
        <Helmet>
    
    <title>HybridRENTY</title>
    <meta name="title" content="HybridRenty" />
    <meta name="description" content="We're excited to announce that our new product will be available in the near future." />
    
    
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://www.hybridhoa.com/hybridrenty" />
    <meta property="og:title" content="Features" />
    <meta property="og:description" content="We're excited to announce that our new product will be available in the near future." />
    
    
    
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://www.hybridhoa.com/hybridrenty" />
    <meta property="twitter:title" content="Features" />
    <meta property="twitter:description" content="We're excited to announce that our new product will be available in the near future." />
    <meta property="og:image" content="../assets/img/preview.png" />
    <link rel='canonical' href='https://www.hybridhoa.com/hybridrenty' />
    <meta name="viewport" content="width=device-width,initial-scale=1"></meta>


    
          </Helmet>
                  {/* modified by mohanraj 25-08-23 end */}
          <Header />
      <PageHeader title="HybridRENTY" />
      <div style={{textAlign:'center'}}>
               {/* modified by mohanraj 25-08-23 start */} 
      <h2 style={{marginTop:50,marginBottom:50}}> We're excited to announce that our new product will be available in the near future.</h2>
              {/* modified by mohanraj 25-08-23 end */}
      </div>
     <Footer />
    </div>
  )
}

export default HybridRenty