import React, { useState } from "react";
import axios from "axios";
import { BASE_URL, CONTACT } from "../utils/ApplicationEndpoints";
import Swal from "sweetalert2";
import { Button, MenuItem, Select, Snackbar, TextField } from "@mui/material";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import MuiAlert from "@mui/material/Alert";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

function HomeContact() {

  const {
    control,
    handleSubmit,
    formState: { errors },
     watch,
  } = useForm();

  const [errorMessage, setErrorMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const onSubmit = (data) => {
    console.log("clicked submit");
    //  const data={
    //   fullName:data.fullName
    //  }
    // event.preventDefault(); // prevent page from refreshing
    console.log("dataaaaaaaaaaa"+JSON.stringify(data) );
    // return;
    axios
      .post(BASE_URL + CONTACT, {
        fullName: data.fullName,
        email: data.email,
        phone: data.phone,
        category: data.category,
        message: data.message,
        refer: data.refer,
        referDesc: data.referDesc,
      })
      .then((res) => {
        console.log(res.status);

        if (res.status === 200 || res.status === 201) {
          if (
            data.category === "SINGLE_HOA" ||
            data.category === "PROPERTY_MANAGEMENT"
          ) {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "We Received your Query we will get in touch shortly.To speed up the process",
             
              showCancelButton: true,
              confirmButtonText: "Schedule a demo",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.open("https://calendly.com/hybridhoa", "_blank", "noreferrer");
              } else if (result.isDenied) {
                console.log("canceled");
              }
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "We Received your Query we will get in touch shortly",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error.response); // Log the error response object
        if (error.response) {
          // Request was made and server responded with a status code
          console.log("error.response.status" + error.response.status);
          console.log("fhgfhgf" + error.response.data.message);

          if (error.response.status !== 200) {
            setErrorMessage(error.response.data.message);
            setOpenSnackbar(true);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  };
  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };


  // const handleChange = (event) => {
  //   console.log("handleChange called");
  //   const { name, value } = event.target;

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));

  //   // update the Controller's value
  //   trigger(name);
  // };
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <section id="contact" className="ud-contact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="ud-contact-content-wrapper">
                <div className="ud-section-title text-center mx-auto">
                  {/* <span style={{fontSize:'30px'}}>CONTACT US</span> */}
                  
                  {/* <h2 style={{ marginBottom: "155px" }}>
                    Don't wait any longer! Reach out to us now to get the best
                    suggestion for our top-notch services
                  </h2> */}

                     {/* modified by mohan raj for HB-XXXX on 29-08-23 start */}

                  <h2 style={{ color: '#363636', marginBottom: '155px' }}>
  Don't wait any longer! Reach out to us now to get the best suggestion for our top-notch services
</h2>
   {/* modified by mohan raj for HB-XXXX on 29-08-23 end */}


                </div>
                <div className="ud-contact-info-wrapper">
                  <div className="ud-single-info">
                    <div className="ud-info-icon">
                      <i className="lni lni-map-marker"></i>
                    </div>
                    <div className="ud-info-meta">
                       {/* modified by mohan raj for HB-XXXX on 29-08-23 start */}
                      {/* <h3>Our Location</h3> */}
                      <h3 style={{ color: '#363636' }}>Our Location</h3>
                       {/* modified by mohan raj for HB-XXXX on 29-08-23 end */}

                      <p>Brimingham,Albama</p>
                    </div>
                  </div>
                  {/* <div className="ud-single-info">
                  <div className="ud-info-icon">
                    <i className="lni lni-envelope"></i>
                  </div>
                  <div className="ud-info-meta">
                    <h5>How Can We Help?</h5>
                    <p>info@yourdomain.com</p>
                    <p>contact@yourdomain.com</p>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5" style={{ marginTop: "60px" }}>
              <div className=" text-center mx-auto">
                <span
                  style={{
                    fontSize: "25px",
                    color: "var(--primary-color)",
                    fontWeight: "600",
                  }}
                >
                  Give us a shout, we hear you
                </span>
              </div>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{ width: "100%" }}
              >
                <MuiAlert
                  severity="error"
                  onClose={handleSnackbarClose}
                  style={{ width: "50%" }}
                >
                  {errorMessage}
                </MuiAlert>
              </Snackbar>

              <div
                className="ud-contact-form-wrapper wow fadeInUp"
                data-wow-delay=".2s"
              >
                {/* <h3 className="ud-contact-form-title">Send us a Message</h3> */}
                <h3 className="ud-contact-form-title" style={{ color: '#363636' }}>Send us a Message</h3>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <Controller
                        name="fullName"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: {
                            value: true,
                            message: "Full name is required",
                          },
                          pattern: {
                            value: /^[a-zA-Z]+(?:\s+[a-zA-Z]+)*$/,
                            message:
                              "Full name should contain only alphabetic characters",
                          },
                          minLength: {
                            value: 3,
                            message:
                              "Full name should be at least 3 characters long",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "Full name should not exceed 30 characters",
                          },
                        }}
                        // validate={value => value.trim()}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Full Name*"
                            error={Boolean(errors?.fullName?.message)}
                            helperText={errors?.fullName?.message}
                            // onChange={(e) => handleFullNameChange(e.target.value)}
                          />
                        )}
                      />
                    </div>

                    <div
                      class="col-lg-12 col-md-12 col-sm-12"
                      style={{ marginTop: 30 }}
                    >
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        //  onChange={handleChange}
                        rules={{
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value:
                              /^(?!.*\.com.*\.com.*\.com)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Please enter a valid email address",
                          },
                        }}
                        validate={(value) => value.trim()}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Email*"
                            error={Boolean(errors?.email?.message)}
                            helperText={errors?.email?.message}
                          />
                        )}
                      />
                    </div>
                    <div
                      class="col-lg-12 col-md-12 col-sm-12"
                      style={{ marginTop: 30 }}
                    >
                      <Controller
                        name="category"
                        control={control}
                        rules={{ required: "Category is required" }}
                        defaultValue="ENQUIRY"
                        // onChange={handleChange}
                        render={({ field }) => (
                          <Select
                            fullWidth
                            {...field}
                            label="Category*"
                            error={Boolean(errors.category)}
                            helperText={errors.category?.message}
                          >
                            <MenuItem value="ENQUIRY">Enquiry</MenuItem>
                            <MenuItem value="TECHNICAL">Technical</MenuItem>
                            <MenuItem value="PROPERTY_MANAGEMENT">
                              Property Management
                            </MenuItem>
                            <MenuItem value="SINGLE_HOA">Single (HOA)</MenuItem>
                            <MenuItem value="DATA_DELETION">Data Collection & Security</MenuItem>
                          </Select>
                        )}
                      />
                      <br />
                      <br />
                      <Form.Label>
                        How Did You Hear About Our Product?
                      </Form.Label>
                      <Controller
                        name="refer"
                        control={control}
                        rules={{ required: "Refer is required" }}
                        defaultValue="FaceBook"
                        // onChange={handleChange}
                        render={({ field }) => (
                          <Select
                            fullWidth
                            {...field}
                            label="Refer*"
                            error={Boolean(errors.refer)}
                            helperText={
                              errors.refer ? errors.refer.message : ""
                            }
                          >
                            <MenuItem value="FaceBook">FaceBook</MenuItem>
                            <MenuItem value="Google">Google</MenuItem>
                            <MenuItem value="Twitter">Twitter</MenuItem>
                            <MenuItem value="Instagram">Instagram</MenuItem>
                            <MenuItem value="TikTok">TikTok</MenuItem>
                            <MenuItem value="LinkedIn">LinkedIn</MenuItem>
                            <MenuItem value="Others">Others</MenuItem>
                          </Select>
                        )}
                      />
                    </div>

                    {watch("refer") === "Others" && (
                      <div
                        className="col-lg-12 col-md-12 col-sm-12"
                        style={{ marginTop: 30 }}
                      >
                        <Controller
                          name="referDesc"
                          control={control}
                          defaultValue=""
                          // onChange={handleChange}
                          // rules={{
                          //   value: true,
                          //   required: "Refer Description is required",
                          // }}
                          rules={{
                            required: { value: true, message: 'Refer Description is required' },
                            validate: {
                              hasNoWhitespace: value => value.trim() !== '' || 'Refer Description is required',
                            },
                            minLength: { value: 2, message: 'Refer Description should be at least 2 characters ' },
                            // maxLength: { value: 250, message: 'Topic should not exceed 250 characters' },
                          }}
                          validate={(value) => value.trim()}
                          render={({ field }) => (
                            <TextField
                              fullWidth
                              {...field}
                              label="Refer Desc*"
                              multiline
                              rows={4}
                              placeholder="Type your message here"
                              error={Boolean(errors?.referDesc)}
                              helperText={errors?.referDesc?.message}
                            />
                          )}
                        />
                      </div>
                    )}
                    <div
                      class="col-lg-12 col-md-12 col-sm-12"
                      style={{ marginTop: 30 }}
                    >
                      <Controller
                        name="phone"
                        control={control}
                        defaultValue=""
                        // onChange={handleChange}
                        rules={{
                          required: {
                            value: true,
                            message: "Phone number is required",
                          },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            message:
                              "Phone number should contain exactly 10 digits",
                          },
                        }}
                        validate={(value) => value.trim()}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Phone*"
                            placeholder="+1 XXX XXX XXXX"
                            error={Boolean(errors?.phone?.message)}
                            helperText={errors?.phone?.message}
                          />
                        )}
                      />
                    </div>

                    <div
                      class="col-lg-12 col-md-12 col-sm-12"
                      style={{ marginTop: 30 }}
                    >
                      <Controller
                        name="message"
                        control={control}
                        defaultValue=""
                        // onChange={handleChange}
                       
                        rules={{
                          required: { value: true, message: 'Message is required' },
                          validate: {
                            hasNoWhitespace: value => value.trim() !== '' || 'Message is required',
                          },
                          minLength: { value: 2, message: 'Message should be at least 2 characters ' },
                          // maxLength: { value: 250, message: 'Topic should not exceed 250 characters' },
                        }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Message*"
                            multiline
                            rows={4}
                            placeholder="Type your message here"
                            error={Boolean(errors?.message)}
                            helperText={errors?.message?.message}
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div style={{ marginTop: 30, textAlign: "center" }}>
                    <Button type="submit" variant="contained">
                      Submit
                    </Button>
                  </div>
                </form>

                {/* <TextField
        label="Email*"
        name="email"
        type="email"
        placeholder="example@yourmail.com"
        value={formData.email}
        onChange={handleChange}
        required
      />
      <TextField
        label="Phone*"
        name="phone"
        placeholder="+885 1254 5211 552"
        value={formData.phone}
        onChange={handleChange}
        required
      />
      <Select
        label="Category*"
        name="category"
        value={formData.category}
        onChange={handleChange}
        required
      >
        <MenuItem value="ENQUIRY">Enquiry</MenuItem>
        <MenuItem value="TECHNICAL">Technical</MenuItem>
        <MenuItem value="PROPERTY_MANAGEMENT">Property Management</MenuItem>
        <MenuItem value="SINGLE_HOA">Single (HOA)</MenuItem>
      </Select>
      <TextField
        label="Message*"
        name="message"
        multiline
        rows={4}
        placeholder="Type your message here"
        value={formData.message}
        onChange={handleChange}
        required
      /> */}
                <br />
                <br />
                <br />
                {/* <Button
        type="submit"
        variant="contained"
        color="primary"
       
      >
        Send Message
      </Button>
    </Form> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <div
        class="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="exampleModalToggleLabel">
                Modal 1
              </h4>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Show a second modal and hide this one with the button below.
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary"
                data-bs-target="#exampleModalToggle2"
                data-bs-toggle="modal"
              >
                Open second modal
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
        tabindex="-1"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalToggleLabel2">
                Modal 2
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              Hide this modal and show the first with the button below.
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
              >
                Back to first
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <a class="btn btn-primary" data-bs-toggle="modal" href="#exampleModalToggle" role="button">Open first modal</a> */}
    </div>
  );
}

export default HomeContact;
