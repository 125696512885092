import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Header from "../Components/Header";

import axios from "axios";
import { BASE_URL, BLOG, IMAGE } from "../utils/ApplicationEndpoints";
import { ArrowForward } from "@mui/icons-material";
import $ from "jquery";
import { Helmet } from "react-helmet";







function Blog(props) {
  const [data, setData] = useState([]);

  const [showMoreArray, setShowMoreArray] = useState(
    Array(props.length).fill(false)
  );

  const [isTruncated, setIsTruncated] = useState([]);

  useEffect(() => {
    const updatedIsTruncated = data.map(item => {
      const div = document.createElement("div");
      div.innerHTML = item.data;
      const text = div.textContent || div.innerText || "";
      return text.length > 150;
    });
    setIsTruncated(updatedIsTruncated);
  }, [data]);

  const handleToggle = index => {
    const updatedShowMoreArray = [...showMoreArray];
    updatedShowMoreArray[index] = !updatedShowMoreArray[index];
    setShowMoreArray(updatedShowMoreArray);
  };

  $(document).ready(function () {
    var content = $("#content");
    var moreLink = $("#more-link");
    var truncated = true;

    if (content.text().length <= 50) {
      moreLink.hide();
    } else {
      content.addClass("truncate");
      moreLink.show();
    }

    moreLink.click(function (e) {
      e.preventDefault();
      if (truncated) {
        content.removeClass("truncate");
        content.addClass("expanded");
        moreLink.text("Less");
        truncated = false;
      } else {
        content.removeClass("expanded");
        content.addClass("truncate");
        moreLink.text("More");
        truncated = true;
      }
    });
  });

  useEffect(() => {
    axios
      .get(BASE_URL + BLOG)
      .then((res) => {
        setData(res.data);
      
  
        // setRating(res.data.rating);
        console.log("tesssssssssssssssssssss" + res.data.imgAlternative);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <React.Fragment>
      <Helmet>
        <title>Blog</title>
        <meta name="title" content="Blog" />
        <meta
          name="description"
          content="Discover insightful articles and  tips on our blog, empowering you to thrive"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hybridhoa.com/blog" />
        <meta property="og:title" content="Blog" />
        <meta
          property="og:description"
          content="Discover insightful articles and  tips on our blog, empowering you to thrive"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://www.hybridhoa.com/blog" />
        <meta property="twitter:title" content="Blog" />
        <meta
          property="twitter:description"
          content="Discover insightful articles and  tips on our blog, empowering you to thrivebridHOA is Onboarding on May 4, 2022 "
         />
         <link rel='canonical' href='https://www.hybridhoa.com/blog' />

      </Helmet>
      <Header />
      {/* <PageHeader title="Blog"/> */}

      <div>
        {/* Banner Start */}
        <section className="ud-page-banner">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ud-banner-content">
                  <h1>Topics of interest...</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* // Banner End */}
      {/* -- ====== Blog Start ====== -- */}
      <section className="ud-blog-grids">
        <div className="container">
          <div className="row">
            {data &&
              data.map((item, index) => (
                <div className="col-lg-4 col-md-6" key={index}>
                  <div className="ud-single-blog">
                    <div className="ud-blog-image">
                    {/* <Link to={`/blogdetail/${item.topic}`}> */}
                    <Link
                         to={`/blogdetail`}
                         state={item}
                          className="ud-about-content"
                        >
                        {Array.isArray(item.imageUrl) ? (
                          item.imageUrl.map((img, index) => (
                            <img
                              key={index}
                              src={BASE_URL + IMAGE + `${img}`}
                              alt="ushfuisdfh"
                              style={{
                                alignContent: "center",
                                alignItems: "center",
                              }}
                              height={250}
                            />
                          ))
                        ) : (
                          <img
                            src={BASE_URL + IMAGE + `${item.imageUrl}`}
                            alt={item.imgAlternative}
                            style={{
                              alignContent: "center",
                              alignItems: "center",
                            }}
                            height={250}
                          />
                        )}
                      </Link>
                    </div>
                    {/* <div className="ud-blog-content">
  <p id="content" class="truncate" dangerouslySetInnerHTML={{ __html: item.data }}></p>
  <a id="more-link" href="#" class="more-link">More</a>
</div> */}
                     <div className="ud-blog-content">
                      <h3> {item.topic}</h3>
                     
                      </div>
                      <br></br>

                    
      <div className="ud-blog-content">
        {isTruncated[index] && !showMoreArray[index] ? (
          <>
            <div dangerouslySetInnerHTML={{ __html: item.data.slice(0, 150) + '...' }}></div>
            <span onClick={() => handleToggle(index)} className="show-more-link">
              Show More
            </span>
          </>
        ) : (
          <>
            <div dangerouslySetInnerHTML={{ __html: item.data }}></div>
            {isTruncated[index] && (
              <span onClick={() => handleToggle(index)} className="show-more-link">
                Less
              </span>
            )}
          </>
        )}
      </div>
  

                    <div
                      className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2"
                      style={{ textAlign: "center" }}
                    >
                      <div>
                      <Link
                         to={`/blogdetail`}
                         state={item}
                          className="ud-about-content"
                        >
                         {/* <Link to={`/blogdetail/${item.topic}`}> */}
                          Read More <ArrowForward />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </React.Fragment>
    // Blog Start
  );
}

export default Blog;
