import React, { useEffect } from "react";
import Header from "../Components/Header";
import PageHeader from "../Components/PageHeader";
import ViewsFeedback from "../Components/ViewsFeedback";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Footer from "../Components/Footer";

function ViewsFeedbackDetail() {
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <Helmet>
        <title>Views & Feedback</title>
        <meta name="title" content="Views & Feedback" />
        <meta
          name="description"
          
          content="Easy-to-use online application for laptops, desktops, tablets, and smartphones alike
HybridHOA can reduce HOA operating costs by more than 50%"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.hybridhoa.com/vfbdetails"
        />
        <meta property="og:title" content="Views & Feedback" />
        <meta
          property="og:description"
          content="Easy-to-use online application for laptops, desktops, tablets, and smartphones alike
HybridHOA can reduce HOA operating costs by more than 50%"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/vfbdetails"
        />
        <meta property="twitter:title" content="Views & Feedback" />
        <meta
          property="twitter:description"
          content="Easy-to-use online application for laptops, desktops, tablets, and smartphones alike
HybridHOA can reduce HOA operating costs by more than 50%"
        />
        <meta property="og:image" content="../assets/img/preview.png" />
        <meta name="viewport" content="width=device-width,initial-scale=1"/>
      </Helmet>
      <Header />
      <PageHeader title="Views And FeedBack" />
      <ViewsFeedback />
      <Footer />
    </div>
  );
}

export default ViewsFeedbackDetail;
