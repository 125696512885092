import React from "react";
import Header from "../Components/Header";
import PageHeader from "../Components/PageHeader";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";

function HybridGateKeeper() {
  return (
    <div>
      <Helmet>
        <title>HybridGATEKEEPER</title>
        <meta name="title" content="HybridGATEKEEPER" />
        <meta name="description" content="HybridGATEKEEPER" />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.hybridhoa.com/hybridgatekeeper"
        />
        <meta property="og:title" content="HybridGATEKEEPER" />
        <meta property="og:description" content="HybridGATEKEEPER" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/hybridgatekeeper"
        />
        <meta property="twitter:title" content="HybridGATEKEEPER" />
        <meta property="twitter:description" content="HybridGATEKEEPER" />
        <meta property="og:image" content="../assets/img/preview.png" />
        {/* modified by mohanraj 25-08-23 start */}
        <link rel='canonical' href='https://www.hybridhoa.com/hybridgatekeeper' />
        <meta name="viewport" content="width=device-width,initial-scale=1"></meta>
        {/* modified by mohanraj 25-08-23 end */}
      </Helmet>
      <Header />
      <PageHeader title="HybridGATEKEEPER" />
      <div style={{ textAlign: "center" }}>
                {/* modified by mohanraj 25-08-23 start */}
        <h2 style={{ marginTop: 50, marginBottom: 50 }}>

          We're excited to announce that our new product will be available in
          the near future.
        </h2>
                {/* modified by mohanraj 25-08-23 end */}
      </div>
      <Footer />
    </div>
  );
}

export default HybridGateKeeper;
