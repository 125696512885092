
import React from 'react';
import table from '../Images/Asset 4table.png';
import pot from '../Images/Asset 7pot.png';
import man from '../Images/Frameman.png';
import {
    Button,
  } from "@mui/material";
  

function HomeFeatureHeader(props) {
    return (
        <div>
            <section
                className="ud-page-banner"
                style={{
                    background: 'linear-gradient(#055E9F, #055E9F 20%)',
                    padding: '50px 0',
                }}
            ></section>

            <section
                className="ud-page-banner"
                style={{
                    background: 'linear-gradient(#089DEE 0%, #089DEE 80%)',
                    padding: '65px 0',
                    position: 'relative',
                }}
            >
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="ud-banner-content">
                            <div className="d-flex">
                                <div className="row">

                                    <div className="col-lg-6 col-md-6 ">
                                        <div className="d-none d-sm-block" >
                                            <img
                                                src={table}
                                                alt="frame"
                                                style={{
                                                    position: 'absolute',
                                                    right: '10%',
                                                    bottom: '0%',
                                                    maxWidth: '100%',
                                                    height: 'auto',
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <h1>{props.title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="d-none d-sm-block" >
                <section
                    className="ud-page-banner"
                    style={{
                        background: 'linear-gradient(#8EDEF2 20%, #8EDEF2 75%)',
                        padding: '25px 0',
                        position: 'relative',
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 ">
                                <img
                                    src={man}
                                    alt="frame"
                                    style={{
                                        position: 'absolute',
                                        right: '90%',
                                        bottom: '50%',
                                        maxWidth: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </div>
                            <div>
      <div style={{ position: 'relative', textAlign: 'center' }}>
        <Button
          variant="outlined"
          onClick={() => {
            window.open('https://calendly.com/hybridhoa', '_blank');
          }}
          sx={{
            background: 'linear-gradient(#089DEE, #089DEE 20%)',
            width: 150,
            color: 'white',
            borderRadius: '5px',
            fontWeight: 'bold',
          }}
        >
          Get Started
        </Button>
      </div>
      <div className='text-center'>
        <div >
          <span>
            <p style={{color:'black'}}>
              30 Days Free Trail. No CC Required
            </p>
          </span>
        </div>
      </div>
    </div>
          
                            <div className="col-lg-6 col-md-6 ">
                                <img
                                    src={pot}
                                    alt="frame"
                                    style={{
                                        position: 'absolute',
                                        right: '5%',
                                        bottom: '5%',
                                        maxWidth: '100%',
                                        height: 'auto',
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default HomeFeatureHeader;
