import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { BASE_URL,FEEDBACK} from '../utils/ApplicationEndpoints';

function Feedback() {
    const [data, setData] = useState([])

    useEffect(() => {
      // axios.defaults.withCredentials = true;
    
    
    
     axios.get(BASE_URL+FEEDBACK).then(
        res=>{
          if(res.status===200){
            setData(res.data[0])
          }
         
    
        }
      ).catch(err=>{
        console.log(err);
      });
    
      
    }, [])
  return (
    
       <section id="testimonials" className="ud-testimonials">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-section-title mx-auto text-center">
              <span style={{fontSize:'30px'}}>Views & Feedback</span>
   {/* modified by mohan raj for HB-2041 on 29-08-23 start */}

              {/* <h2>{data.topic}</h2> */}
              <h2 style={{ color: '#363636' }}>{data.topic}</h2>
   {/* modified by mohan raj for HB-2041 on 29-08-23 end */}

              <p>
                {data.description}
              </p>
            </div>
          </div>
          </div>
          </div>
</section>    
  )
}

export default Feedback
