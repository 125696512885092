import React, { useCallback, useEffect, useState } from "react";
import HomeAbout from "../Components/HomeAbout";
import PageHeader from "../Components/PageHeader";
import Header from "../Components/Header";
import "./About.css";
import axios from "axios";
import { BASE_URL, TIMELINE } from "../utils/ApplicationEndpoints";
import { Helmet } from "react-helmet";
import Footer from "../Components/Footer";

function About() {
  const [data, setData] = useState([]);

  const fetchData = useCallback(async () => {
    console.log("fetch data ");
    try {
      const response = await axios.get(BASE_URL + TIMELINE);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <Helmet>
        <title>About</title>
        <meta name="title" content="About" />
        <meta
          name="description"
          content="Explore HybridHOA: Empower HOAs with efficient operations, enhanced communication, and effective dues management"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hybridhoa.com/about" />
        <meta property="og:title" content="About" />
        <meta
          property="og:description"
          content="Explore HybridHOA: Empower HOAs with efficient operations, enhanced communication, and effective dues management"
        
        />
        {/* modified by mohanraj 25-08-23 start */}
           <link rel='canonical' href='https://www.hybridhoa.com/about' />

           <meta name="viewport" content="width=device-width,initial-scale=1"></meta>

        {/* modified by mohanraj 25-08-23 end */}
    

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/about"
        />
        <meta property="twitter:title" content="About" />
        <meta
          property="twitter:description"
          content="Explore HybridHOA: Empower HOAs with efficient operations, enhanced communication, and effective dues management"
        />
         <meta property="og:image" content="../assets/img/preview.png" />
     
      </Helmet>
      <Header />
      <PageHeader title="Our journal reference" />
      <HomeAbout title="About Us" readmore="false" />
      <div class="container">
        <div class="main-timeline">
          {data.length > 0
            ? data.map((item) => (
                <div class="timeline">
                  <div class="icon"></div>
                  <div class="date-content">
                    <div class="date-outer">
                      <span class="date">
                        <span class="year">{item.year}</span>
                      </span>
                    </div>
                  </div>
                  <div class="timeline-content">
                    {/* <h2 class="title">{item.topic}</h2> */}
                     {/* modified by mohan raj for HB-XXXX on 30-08-23 start */}
                    <h2 className="title" style={{ color: '#363636' }}>{item.topic}</h2>
                     {/* modified by mohan raj for HB-XXXX on 30-08-23 end */}
                    <p class="description">{item.description}</p>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
      <Footer />
    </div>
  
  );
}

export default About;
