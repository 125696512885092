import React from 'react';
import { Link } from 'react-router-dom';

function BlogHeader() {
  return (
    <header className="ud-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 ">
            <nav className="navbar navbar-expand-lg">
              <Link to="/" className="navbar-brand">
                <img
                  src="https://hybridhoa.com/assets/images/HHOA_logo2.png"
                  alt="Logo"
                />
              </Link>
              <button className="navbar-toggler" type="button">
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
                <span className="toggler-icon"> </span>
              </button>

              <div className="navbar-collapse">
                <ul id="nav" className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <Link to="/" className="ud-menu-scroll">
                      Home
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/about" className="ud-menu-scroll">
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/pricing" className="ud-menu-scroll">
                      Pricing
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contactus" className="ud-menu-scroll">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item nav-item-has-children">
                    <a > More </a>
                    <ul className="ud-submenu">
                      <li className="ud-submenu-item">
                        <Link
                          to="/featuresdetail"
                          className="ud-submenu-link"
                        >
                          Features
                        </Link>
                      </li>
                      <li className="ud-submenu-item">
                        <Link to="/blog" className="ud-submenu-link">
                          Blog
                        </Link>
                      </li>
                      <li className="ud-submenu-item">
                        <Link to="/pricing" className="ud-submenu-link">
                          Pricing
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item nav-item-has-children" style={{ marginLeft: '40px' }}>
                    <a  >Login </a>
                    <ul className="ud-submenu">
                      <li className="ud-submenu-item">
                        <a
                          href="https://group.hybridhoa.com/#/group-portal/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ud-submenu-link"
                        >
                          Admin Login
                        </a>
                      </li>
                      <li className="ud-submenu-item">
                        <a
                          href="https://user.hybridhoa.com/#/user-portal/login"
                          target="_blank"
                          rel="noreferrer"
                          className="ud-submenu-link"
                        >
                          Home Owner Login
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="pt-3">
                    <a  rel=" noreferrer"
                      className="ud-main-btn ud-white-btn ud-main-btns"
                      target="_blank"
                      href="https://calendly.com/hybridhoa"
                      style={{ backgroundColor: 'white', color: '#2748b4', marginLeft: 50 }}
                    >
                      Schedule a Demo
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  );
}

export default BlogHeader;
