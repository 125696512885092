import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { BASE_URL, FAQ, FAQ_CARD } from '../utils/ApplicationEndpoints';
import { ArrowForward } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';


function HomeFaq(props) {

  const location = useLocation();

  const [FaqCard,SetFaqCard]=useState([]);
  const [topic,SetTopic] = useState('')
  const [description,setDescription]= useState('')
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + FAQ);

      SetTopic(response.data[0].topic)
      setDescription(response.data[0].description)
      console.log("testttttttt"+response.data[0].topic);
    } catch (error) {
      console.error('Error fetching faq data:', error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);

  const fetchFaqCardData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + FAQ_CARD);
      SetFaqCard(response.data);
    } catch (error) {
      console.error('Error fetching feature data:', error);
    }
  }, []);

  useEffect(() => {
    fetchFaqCardData();
  }, [fetchFaqCardData]);

  const  isUsRoute = location.pathname.startsWith('/us');
const isInRoute=location.pathname.startsWith('/in');

  return (
    <div>
      {props.limit ? 
       <section id="faq" className="ud-faq" style={{marginTop:20}}>
       <div className="shape">
         <img src="assets/images/faq/shape.svg" alt="shape" height={'95%'} title='shape' loading='eager'  width={'actualWidth'}/>
       </div>
       <div className="container">
         <div className="row">
           <div className="col-lg-12">
             <div className="ud-section-title text-center mx-auto">
               <span style={{fontSize:'30px'}}>FAQ</span>
              
               {topic && <h2 className="ud-feature-title">{topic}</h2>}
               <p>
                 {description}
               </p>
             </div>
           </div>
         </div>
         <div className="row">
   {FaqCard.slice(0, 6).map((faqCard, index) => {
     const collapseId = `collapse-${faqCard.id}-${index}`;
     return (
       <div className="col-lg-6" key={faqCard.id}>
         <div className="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
           <div className="accordion">
             <button
               className="ud-faq-btn collapsed"
               data-bs-toggle="collapse"
               data-bs-target={`#${collapseId}`}
             >
               <span className="icon flex-shrink-0">
                 <i className="lni lni-chevron-down"></i>
               </span>
               <span>{faqCard.topic}</span>
             </button>
             <div id={collapseId} className="accordion-collapse collapse">
               <div className="ud-faq-body">
                 {faqCard.description}
               </div>
             </div>
           </div>
         </div>
       </div>
     );
   })} 
 </div>
 {props.readmore ? 
          <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2" style={{textAlign:'center'}}>
           <div >
           <a href={'/faqdetail'} className='ud-about-content'>Read More <ArrowForward/> </a> 
           {/* <Link to={isUsRoute ? '/us/faqdetail' : isInRoute ? '/in/faqdetail' : ''} className='ud-about-content'>Read More <ArrowForward/> </Link> */}
             </div>
         
        </div> : null}
 
       </div>
     </section>
     :
     <section id="faq" className="ud-faq">
     <div className="shape">
       {/* <img src="assets/images/faq/shape.svg" alt="shape" /> */}
     </div>
     <div className="container">
       <div className="row">
         <div className="col-lg-12">
           <div className="ud-section-title text-center mx-auto">
             <span style={{fontSize:'30px'}}>FAQ</span>
             {topic && <h2 className="ud-feature-title">{topic}</h2>}
             <p>
               {description}
             </p>
           </div>
         </div>
       </div>
       <div className="row">
 {FaqCard.map((faqCard, index) => {
   const collapseId = `collapse-${faqCard.id}-${index}`;
   return (
     <div className="col-lg-6" key={faqCard.id}>
       <div className="ud-single-faq wow fadeInUp" data-wow-delay=".1s">
         <div className="accordion">
           <button
             className="ud-faq-btn collapsed"
             data-bs-toggle="collapse"
             data-bs-target={`#${collapseId}`}
           >
             <span className="icon flex-shrink-0">
               <i className="lni lni-chevron-down"></i>
             </span>
             <span>{faqCard.topic}</span>
           </button>
           <div id={collapseId} className="accordion-collapse collapse">
             <div className="ud-faq-body">
               {faqCard.description}
             </div>
           </div>
         </div>
       </div>
     </div>
   );
 })}
</div>
{props.readmore ? 
        <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2" style={{textAlign:'center'}}>
         <div >
         {/* <a href={isUsRoute ? '/us/faqdetail' : isInRoute ? '/in/faqdetail' : ''} className='ud-about-content'>Read More <ArrowForward/> </a> */}
         {/* <Link to ={isUsRoute ? '/us/faqdetail' : isInRoute ? '/in/us/faqdetail' : ''} className="ud-submenu-link" >Read More <ArrowForward/></Link> */}
         <a href={'/faqdetail'} className='ud-about-content'>Read More <ArrowForward/> </a> 

           </div>
       
      </div> : null}

     </div>
   </section>}
        
    </div>
  )
}

export default HomeFaq
