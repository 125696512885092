import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_URL, FEATURE_CATEGORY, FEATURE_CARD,IMAGE_FEATURE } from '../utils/ApplicationEndpoints';
import { Helmet } from "react-helmet";
import hybridhoa from '../Images/Hybridhoalogo.png'
import { ArrowForward } from '@mui/icons-material';



function Feature(props) {
  const [data, setData] = useState([]);
  const [FeatureCarddata, setFeatureCarddata] = useState([]);
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };




  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + FEATURE_CATEGORY);
      setData(response.data);
      console.log(response.data);
      console.log(response.data[0].id);
    } catch (error) {
      console.error('Error fetching feature data:', error);
    }
  }, []);


  useEffect(() => {
    fetchData();
  }, [fetchData]);


  const fetchDataCard = useCallback(async () => {
    try {
      const responsecard = await axios.get(BASE_URL + FEATURE_CARD);

      setFeatureCarddata(responsecard.data);
      console.log(responsecard.data);
      const categoryCounts = {};

      // Iterate through the featureData and count items for each category
      FeatureCarddata.forEach((item) => {
        const category = item.category;
        categoryCounts[category] = (categoryCounts[category] || 0) + 1;
      });
  
      // Print the counts with category names in the console
      Object.entries(categoryCounts).forEach(([category, count]) => {
        console.log(`Category: ${category}, Count: ${count}`);
      });
    } catch (error) {
      console.error('Error fetching feature data:', error);
    }
  }, [FeatureCarddata]);

  useEffect(() => {
    fetchDataCard();
  }, []);



  useEffect(() => {
    if (data.length > 0) {
      setSelectedNavItem(data[0].id);
      
    }
  }, [data]);

  const myStyle = {
    listStyle: 'none',
    padding: 0,

  };

  const listItemStyle = {
    position: 'relative',
    paddingLeft: '40px',
    marginBottom: '10px',
    marginLeft: '60px',
    height: 'auto',
    width: 'auto',
    fontSize: '16px',
  };

  const iconStyle = {
    content: `url(${hybridhoa})`,
    position: 'absolute',
    left: '0',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '20px',
    height: '20px',
  };

  

  const handleClick = (id) => {
    setSelectedNavItem(id);
    console.log(`Clicked on: ${id}`);
    // Add any additional logic you need here
  };




  const getSpanStyle = (id) => {
    const commonStyle = {
      cursor: 'pointer',
      fontSize: '17px',

    }; return {
      ...commonStyle,
      color: selectedNavItem === id ? 'blue' : 'black',
      fontWeight: selectedNavItem === id ? 'bold' : 'normal',
    };
  };



  return (
    <React.Fragment>
     <Helmet>


<meta name="title" content="Features" />
<meta name="description" content="Efficiently manage your community with our HybridHOA features like virtual manager,ARC, accounting, polling and more" />


<meta property="og:type" content="website" />
<meta property="og:url" content="https://www.hybridhoa.com/featuresdetail" />
<meta property="og:title" content="Features" />
<meta property="og:description" content="Efficiently manage your community with our HybridHOA features like virtual manager,ARC, accounting, polling and more" />



<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://www.hybridhoa.com/featuresdetail" />
<meta property="twitter:title" content="Features" />
<meta property="twitter:description" content="Efficiently manage your community with our HybridHOA features like virtual manager,ARC, accounting, polling and more" />
<meta property="og:image" content="../assets/img/preview.png" />
<link rel='canonical' href='https://www.hybridhoa.com/featuresdetail' />

<meta name="viewport" content="width=device-width,initial-scale=1" />


</Helmet>


      <div class='container'>
      {data.length > 0 ? (
        <div class='row'>
          <div class='col-lg-3 col-md-6 d-none d-lg-block'>
            <div class='d-flex flex-column justify-content-center' style={{ marginTop: '90px' }}>
              <ul>
                {data.map((item) => (
                  <li key={item.title}>
                    <a 
                      className="nav-link"
                      style={{
                        position: 'relative',
                        ...getSpanStyle(item.id),
                      }}
                      onClick={() => handleClick(item.id)}
                    >
                      <span
                        className="nav-line"
                        style={{
                          position: 'absolute',
                          left: '-15px',
                          top: '0',
                          height: '100%',
                          width: '3px',
                          backgroundColor: selectedNavItem === item.id ? 'blue' : '#ECECEC',
                        }}
                      ></span>
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>






          <div className='col-lg-9 col-md-6 d-none d-lg-block'>
  <div className='d-flex justify-content-center'>
    <div style={{ position: 'relative' }}>
      {FeatureCarddata
        .filter(feature => parseInt(selectedNavItem) === parseInt(feature.category))
        .slice(0, 2)
        .map((feature, index) => (
          <div key={index} className={`card mb-6 ${index % 2 === 0 ? 'even' : 'odd'}`} style={{ width: '900px', margin: '15px auto',   backgroundColor: hoveredIndex === index ? '#B1CEFF' : '#F9F9F9', border: 'none', borderRadius: '30px' }} onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}>
            <div className="row g-0">
              {index % 2 === 0 ? (
                // Even index, image on the right
                <>
                  <div className="col-md-8 col-sm-12">
                    <div className="card-body">
                      <h5 className="card-title" style={{ marginLeft: '100px',color: hoveredIndex === index ? 'white' : 'black' }}>{feature.topic}</h5>
                      <br />
                      <ul style={myStyle}>
                        {feature.descriptionList.slice(0, 3).map((description, descIndex) => (
                          <li key={descIndex} style={{...listItemStyle,color: hoveredIndex === index ? 'white' : 'black'}}>
                            <span style={iconStyle}></span>
                            {description.content}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <img src={BASE_URL + IMAGE_FEATURE + `${feature.imageUrl}`} style={{ width: '200px', height: '150px', marginTop: '10px' }} className="img-fluid rounded-start" alt={feature.imgAlternative} />
                  </div>
                </>
              ) : (
                // Odd index, image on the left
                <>
                  <div className="col-md-4 col-sm-12">
                    <img src={BASE_URL + IMAGE_FEATURE + `${feature.imageUrl}`} style={{ width: '200px', height: '150px', marginTop: '10px' }} className="img-fluid rounded-start" alt={feature.imgAlternative} />
                  </div>
                  <div className="col-md-8 col-sm-12">
                    <div className="card-body">
                      <h5 className="card-title" style={{ marginLeft: '100px',color: hoveredIndex === index ? 'white' : 'black' }}>{feature.topic}</h5>
                      <br />
                      <ul style={myStyle}>
                        {feature.descriptionList.slice(0, 3).map((description, descIndex) => (
                          <li key={descIndex} style={{...listItemStyle,color: hoveredIndex === index ? 'white' : 'black'}}>
                            <span style={iconStyle}></span>
                            {description.content}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
    </div>




  </div>
</div>








        </div>
        ) : null}
        {props.readmore ? 
     <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2 d-none d-lg-block" style={{textAlign:'center'}}>
      <div >
      <a href='/featuresdetail' className='ud-about-content'>Read More <ArrowForward/> </a>
        </div>
    
   </div> : null}
      </div>





      {/* ----------------------------------------Smaller screens--------------------------- */}

      <div class='container'>
        <div className='d-lg-none'>
      {data.length > 0 ? (
        <div class='row'>
        <div className='d-lg-none'>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', textAlign: 'center' }}>
        {data.map((item) => (
          <a
          
            key={item.id} 
            className="nav-link"
            style={{
              ...getSpanStyle(item.id),
              marginRight: '20px',
              display: 'inline-block',
            }}
            onClick={() => handleClick(item.id)}
          >
            {item.title}
          </a>
        ))}
      </div>
    </div>
 

 <div class='d-lg-none'>
    {FeatureCarddata.filter(feature =>  parseInt(selectedNavItem) === parseInt(feature.category))
        .slice(0, 2)
        .map((feature, index) => (
  <div key={index} className={`card mb-6 ${index % 2 === 0 ? 'even' : 'odd'}`} style={{ maxWidth: '1300px', margin: '25px auto',backgroundColor: hoveredIndex === index ? '#B1CEFF' : '#F9F9F9', border: 'none', borderRadius: '30px' }} onMouseEnter={() => handleMouseEnter(index)}
  onMouseLeave={handleMouseLeave}>
    <div className="row g-0">
      {index % 2 === 0 ? (
       
        <>
          <div className="col-md-8 col-sm-12">
            <div className="card-body">
              <h5 className="card-title" style={{ marginLeft: '125px',color: hoveredIndex === index ? 'white' : 'black'  }}>{feature.topic}</h5>
              <br />
              <ul style={myStyle}>
                {feature.descriptionList.slice(0, 3).map((description, descIndex) => (
                  <li key={descIndex} style={{...listItemStyle,color: hoveredIndex === index ? 'white' : 'black'}}>
                    <span style={iconStyle}></span>
                    {description.content}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            <img src={BASE_URL + IMAGE_FEATURE + `${feature.imageUrl}`} style={{ width: '300px', height: '250px', marginTop: '10px' }} className="img-fluid rounded-start" alt={feature.imgAlternative} />
          </div>
        </>
      ) : (
        
        <>
          <div className="col-md-4 col-sm-12">
            <img src={BASE_URL + IMAGE_FEATURE + `${feature.imageUrl}`} style={{ width: '300px', height: '250px', marginTop: '10px' }} className="img-fluid rounded-start" alt={feature.imgAlternative} />
          </div>
          <div className="col-md-8 col-sm-12">
            <div className="card-body">
              <h5 className="card-title" style={{ marginLeft: '125px' }}>{feature.topic}</h5>
              <br />
              <ul style={myStyle}>
                {feature.descriptionList.map((description, descIndex) => (
                  <li key={descIndex} style={listItemStyle}>
                    <span style={iconStyle}></span>
                    {description.content}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  </div>
))}
</div>



        </div>
          ) : null}
          {props.readmore ? 
       <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2" style={{textAlign:'center'}}>
        <div >
        <a href='/featuresdetail' className='ud-about-content'>Read More <ArrowForward/> </a>
          </div>
      
     </div> : null}
     </div>
      </div>






    </React.Fragment>
  );
}

export default Feature;
