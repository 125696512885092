import Header from "../Components/Header";
import PageHeader from "../Components/PageHeader";
import {
  Button,
  Card,
  CardContent,
  Typography,
  
} from "@mui/material";
import {
 
  Check,
  Clear,
  Flaky,
  MultipleStop,
  MultipleStopOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import {
  BASE_URL,
  PRICINGCONTENT,
  PRICINGHERO,
} from "../utils/ApplicationEndpoints";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {  useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Switch from '@mui/material/Switch';
import bluearrow from '../Images/savings-arrow.svg'
import Footer from "../Components/Footer";



function PricingDetails() {
  const [data, setData] = useState([]);
  const [pricingdata, setpricingdata] = useState([]);
  const [category, setCategory] = useState([]);


 // <----------------------toggle switch---------------------------->
 const [isToggled, setToggled] = useState(false);

 const handleToggle = () => {
   const newState = !isToggled;
   setToggled(newState);
  //  console.log(newState);
 };

  const anchorStyle = {
    fontSize: '16px', 
    color:'gray',
   
  };
  
  
  
    const cardHoverStyle = {
      transform: 'scale(1.02, 1.02)',
      WebkitTransform: 'scale(1.02, 1.02)',
      backfaceVisibility: 'hidden',
      willChange: 'transform',
      boxShadow: '0 1rem 3rem rgba(221, 218, 218, 1) !important',
    };
    


    const savecolor = {
      marginLeft: '-30px',
      marginBottom: '275px',
      color: '#0070E0',
      position: 'absolute',
      marginTop: '-50px',
      fontSize:'15px',
    };
    
    const arrowposition ={
      marginBottom:'20px',
      // filter: 'hue-rotate(240deg)',
      // transform: 'rotate(160deg)',
    }

    const aligncenter = {
      marginRight:'-50px',
     }
 


  
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + PRICINGHERO);
      setData(response.data);
      console.log(data);
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);



 
  const fetchDatapricing = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + PRICINGCONTENT);
      const pricingData = response.data;
      pricingData.sort((a, b) => a.pricingOrder - b.pricingOrder);
      // console.log("testttttttttttttttttttttttttttt" + JSON.stringify(pricingData));

      console.log(
        "testttttttttttttttttttttttttttt" + JSON.stringify(pricingData)
      );
      setpricingdata(pricingData);

      const uniqueCategories = pricingData.reduce((unique, item) => {
        if (!unique.includes(item.category)) {
          unique.push(item.category);
        }
        return unique;
      }, []);
      uniqueCategories.sort((a, b) => {
        const categoryA = pricingData.find((item) => item.category === a);
        const categoryB = pricingData.find((item) => item.category === b);
        return categoryA.categoryOrder - categoryB.categoryOrder;
      });

      setCategory(uniqueCategories);
    } catch (error) {
      console.error("Error fetching pricing data:", error);
    }
  }, []);

  useEffect(() => {
    fetchDatapricing();
  }, []);

  const location = useLocation();
  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>Pricing</title>
        <meta name="title" content="Pricing" />
        <meta
          name="description"
          content="Explore our cost effective pricing plans designed to fit your community's needs. Find the perfect solution with HybridHOA"
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.hybridhoa.com/pricing" />
        <meta property="og:title" content="Pricing" />
        <meta
          property="og:description"
          content="Explore our cost effective pricing plans designed to fit your community's needs. Find the perfect solution with HybridHOA"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/pricing"
        />
        <meta property="twitter:title" content="Pricing" />
        <meta
          property="twitter:description"
          content="Explore our cost effective pricing plans designed to fit your community's needs. Find the perfect solution with HybridHOA"
        />

        {/* modified by mohanraj 25-08-23 start */}
<link rel='canonical' href='https://www.hybridhoa.com/pricing' />
<meta name="viewport" content="width=device-width,initial-scale=1"></meta>
        {/* modified by mohanraj 25-08-23 end */}

        <meta property="og:image" content="../assets/img/preview.png" />
        
      </Helmet>
      <Header />
      <PageHeader title="Our Pricing Options for better management" />
      &nbsp;

      {/* <div className="row" >
     

      <Switch style={switchStyle}{...label} />
      <Switch style={switchStyle} />
     
    </div>    */}
    
   
    <div className="text-center" style={aligncenter} >
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <span style={{ fontWeight: !isToggled ? 'bold' : 'normal' }}>Pay Monthly</span>
        <Switch checked={isToggled} onClick={handleToggle} />
        <span style={{ fontWeight: isToggled ? 'bold' : 'normal' }}>
          Pay Yearly &nbsp;
        </span> 
        
        <div>
    <img alt="" src={bluearrow} style={arrowposition}   width="30" 
        height="30" />

    </div>

    <div> 
      <span><a style={savecolor}>Save 10%</a></span></div>
      </div>

    </div> &nbsp;
      <div className="container">
        
        {data.length > 0 ? (
          <div className="row">
            
            <div className="col-lg-4 col-md-12 mb-4">
      <Card
        sx={{
          height: '95%',
          width:'75%',
          position: 'relative',
          '&:hover': cardHoverStyle,
          '&:hover::after': { opacity: 1 },
          borderRadius: '10px',
          left:'25%',
          '@media (max-width: 768px)': {
            // Adjust styles for screens smaller than 768px
            width: '100%',
            left: '0%',
          },
         
        }}
     
      >
        <CardContent>

          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            <Typography variant="h5" component="div" gutterBottom>
             <h2>{data[0].topic}</h2> 
            </Typography>
           
            <Typography>
           
              <span style={{fontSize:'15px'}}>
                Starts From 
              </span>
            </Typography>
          
            <Typography variant="h4" style={{marginTop:'10px'}}>
            {isToggled  ? (
        <p>
          <h3> $ {data[0].amount - (0.1 * data[0].amount)}<a style={anchorStyle}> / unit</a></h3>
          </p>
      ) : (
        <p>
        <h3> ${data[0].amount}<a  style={anchorStyle}> / unit</a>
        </h3>
        </p>
      )}
             </Typography> 
            <br/>
            {/* <Typography style={{ color: "black" ,textAlign:'left' }} 
                    dangerouslySetInnerHTML={{
                      __html:data[0].description,
                    }} /> */}

           
          </div>
        </CardContent>
        
        <div className="card-body text-center">
        <Button
                      variant="outlined"
                      onClick={() => {
                        window.open(data[0].buttonUrl, "_blank");
                      }}
                      sx={{ 
                        position: 'absolute',
                        bottom: 20,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                        // height: 50 
                        width:200,
                       
                      }}
                    >
                      Schedule a Demo
                    </Button>
        </div>
      </Card>
    </div>




    <div className="col-lg-4 col-md-12 mb-4">
      <Card
        sx={{
          height: '95%',
          width:'75%',
          position: 'relative',
          '&:hover': cardHoverStyle,
          '&:hover::after': { opacity: 1 },
          borderRadius: '10px',
          left:'13%',
          '@media (max-width: 768px)': {
            // Adjust styles for screens smaller than 768px
            width: '100%',
            left: '0%',
          },
         
        }}
        // className="h-100 shadow-lg"
      >
        <CardContent>
          
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            <Typography variant="h5" component="div" gutterBottom>
             <h2>{data[1].topic}
              </h2> 
            </Typography>
            <Typography>
           
           <span style={{fontSize:'15px'}}>
             Starts From 
           </span>
         </Typography>
            <Typography variant="h3" style={{marginTop:'10px'}}>
            {isToggled  ? (
        <p>
          <h3> ${data[1].amount - (0.1 * data[1].amount)}<a  style={anchorStyle}> / unit</a></h3>
          </p>
      ) : (
        <p>
        <h3> ${data[1].amount}<a style={anchorStyle}> / unit</a>
        </h3>
        </p>
      )}
              </Typography>
            <br/>
            {/* <Typography style={{ color: "black",textAlign:'left' }} 
                    dangerouslySetInnerHTML={{
                      __html:data[1].description,
                    }} /> */}

            
          </div>
        </CardContent>
        
        <div className="card-body text-center">
        <Button
                      variant="contained"
                      onClick={() => {
                        window.open(data[1].buttonUrl, "_blank");
                      }}
                      sx={{ 
                        position: 'absolute',
                        bottom: 20,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                         
                        width:200,
                        
                      }}
                     
                    >
                      Schedule a Demo
                    </Button>
        </div>
      </Card>
    </div>




    <div className="col-lg-4 col-md-12 mb-4">
      <Card
        sx={{
          height: '95%',
          width:'75%',
          position: 'relative',
          '&:hover': cardHoverStyle,
          '&:hover::after': { opacity: 1 },
          borderRadius: '10px',
          '@media (max-width: 768px)': {
            // Adjust styles for screens smaller than 768px
            width: '100%',
            left: '0%',
          },
         
        }}
        // className="h-100 shadow-lg"
      >
        <CardContent>
          
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>  
            <Typography variant="h5" component="div" gutterBottom>
            <h2>{data[2].topic}</h2>  
            </Typography>
            <Typography>
           
           <span style={{fontSize:'15px'}}>
             Starts From 
           </span>
         </Typography>
            <Typography variant="h3" style={{marginTop:'10px'}} >
            {isToggled  ? (
        <p>
          <h3> ${data[2].amount - (0.1 * data[2].amount)}<a style={anchorStyle}> / unit</a></h3>
          </p>
      ) : (
        <p>
        <h3> ${data[2].amount}<a style={anchorStyle}> / unit</a>
        </h3>
        </p>
      )}
              </Typography>
            <br/>

            {/* <Typography style={{ color: "black" ,textAlign:'left'}} 
                    dangerouslySetInnerHTML={{
                      __html:data[2].description,
                    }} /> */}

           
          </div>
        </CardContent>
        <div
                    style={{ textAlign: "center", marginTop: 20 }}
                    className="pt-2"
                  >
                    
                    <Button
                      variant="outlined"
                      onClick={() => {
                        window.open(data[2].buttonUrl, "_blank");
                      }}
                      sx={{ 
                        position: 'absolute',
                        bottom: 20,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                        // height: 50 
                        width:200
                      }}
                    >
                      Schedule a Demo
                    </Button>
                  </div>
      </Card>
    </div>

            {/* <div className="col-lg-3 col-md-6 col-sm-12">
              <Card sx={{ minHeight: 600,position:'relative'}} className="mt-5 mb-5">
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <RocketLaunch />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <h2 style={{ color: "black" }}>{data[1].topic}</h2>
                  </div>
                  <div style={{ textAlign: "left" }}>
                  <Typography style={{ color: "black" }} 
                    dangerouslySetInnerHTML={{
                      __html:data[1].description,
                    }} />
                  
                  </div>
                  <div
                    style={{ textAlign: "center", marginTop: 80 }}
                    className="pt-2"
                  >
                     <h3 style={{ textAlign: "center",position: 'absolute',
                        bottom: 70,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                      
                        width:200 }}>
                      <b>${data[1].amount}</b>
                    </h3>
                    <Button
                      variant="contained"
                      onClick={() => {
                        window.open(data[1].buttonUrl, "_blank");
                      }}
                      sx={{ 
                        position: 'absolute',
                        bottom: 16,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                         
                        width:200
                      }}
                     
                    >
                      Schedule a Demo
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div> */}

            {/* <div className="col-lg-3 col-md-6 col-sm-12">
              <Card sx={{ minHeight: 600,position:'relative' }} className="mt-5 mb-5">
                <CardContent>
                  <div style={{ textAlign: "center" }}>
                    <Telegram />
                  </div>
                  <div style={{ textAlign: "center" }}>
                    <h2 style={{ color: "black" }}>{data[2].topic}</h2>
                  </div>
                  <div style={{ textAlign: "left" }}>
                   
                   <Typography style={{ color: "black" }} 
                    dangerouslySetInnerHTML={{
                      __html:data[2].description,
                    }} />
                   
                  </div>
                  <div style={{ textAlign: "center" }} className="pt-2">
                  <h3 style={{ textAlign: "center",position: 'absolute',
                        bottom: 70,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                       
                        width:200 }}>
                      <b>${data[2].amount}</b>
                    </h3>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        window.open(data[2].buttonUrl, "_blank");
                      }}
                      sx={{ 
                        position: 'absolute',
                        bottom: 16,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                       
                        width:200
                      }}
                    >
                      Schedule a Demo
                    </Button>
                  </div>
                </CardContent>
              </Card>
            </div> */}
          </div>
        ) : null}

        <div className="row">
          <div className="col-lg-1 col-md-12 col-sm-12"></div>
          <div className="col-lg-10 col-md-12 col-sm-12">
            <Card sx={{ textAlign: "center", marginBottom: 5 }}>
              <CardContent>
                <p>
                  <Check style={{ color: "#00ff00" }}></Check> Included in the
                  plan &nbsp; <Clear style={{ color: "#ff0000" }}></Clear> Not
                  included in the plan &nbsp; &nbsp;{" "}
                  <Flaky style={{ color: "#2748b4" }}></Flaky> Extra charges may
                  apply &nbsp; &nbsp;{" "}
                  <MultipleStop style={{ color: "#2748b4" }}></MultipleStop>{" "}
                  Optional{" "}
                </p>
                {/* <p><Clear></Clear> Not Include in the Plan</p> */}
                {/* <p><Check></Check> Include in the Plan</p> */}
              </CardContent>
            </Card>
          </div>

          <div className="col-lg-1 col-md-12 col-sm-12"></div>
        </div>

        {category.length > 0
          ? category.map((item) => (
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead style={{ backgroundColor: "#E7E7ED" }}>
                    <TableRow
                      sx={{
                        "& td, & th": {
                          borderTop: "1px solid rgba(224, 224, 224, 1)",
                        },
                       
                      }}
                    >
                      <TableCell>{item}</TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "none" }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "none" }}
                      ></TableCell>
                      <TableCell
                        align="center"
                        style={{ border: "none" }}
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pricingdata.length > 0
                      ? pricingdata.map((pri, index) => {
                          return pri.category === item ? (
                            <TableRow
                              sx={{
                                "& td, & th": {
                                  border: "1px solid rgba(224, 224, 224, 1)",
                                },
                              }}
                              key={index}
                            >
                              <TableCell style={{ width: "25%" }}>
                                {pri.topic}
                              </TableCell>
                              <TableCell align="center">
                                {/* {pri.basic ? (
            <Check style={{ fontSize: 15, color: "#00ff00", fontWeight: "bold" }} />
          ) : (
            <Clear style={{ fontSize: 15, color: "#ff0000" }} />
          )} */}

                                {pri.basic === "Y" ? (
                                  <Check
                                    style={{
                                      fontSize: 20,
                                      color: "#00ff00",
                                      fontWeight: "bold",
                                    }}
                                  />
                                ) : pri.basic === "N" ? (
                                  <Clear
                                    style={{ fontSize: 20, color: "#ff0000" }}
                                  />
                                ) : pri.basic === "O" ? (
                                  <MultipleStopOutlined
                                    style={{ fontSize: 25, color: "#2748b4" }}
                                  />
                                ) : pri.basic === "Extra charges may apply" ? (
                                  <Flaky
                                    style={{ fontSize: 20, color: "#2748b4" }}
                                  />
                                ) : null}
                              </TableCell>
                              <TableCell align="center">
                                {/* {pri.extended ? (
            <Check style={{ fontSize: 15, color: "#00ff00", fontWeight: "bold" }} />
          ) : (
            <Clear style={{ fontSize: 15, color: "#ff0000" }} />
          )} */}

                                {pri.extended === "Y" ? (
                                  <Check
                                    style={{
                                      fontSize: 20,
                                      color: "#00ff00",
                                      fontWeight: "bold",
                                    }}
                                  />
                                ) : pri.extended === "N" ? (
                                  <Clear
                                    style={{ fontSize: 20, color: "#ff0000" }}
                                  />
                                ) : pri.extended === "O" ? (
                                  <MultipleStopOutlined
                                    style={{ fontSize: 25, color: "#2748b4" }}
                                  />
                                ) : pri.extended ===
                                  "Extra charges may apply" ? (
                                  <Flaky
                                    style={{ fontSize: 20, color: "#2748b4" }}
                                  />
                                ) : null}
                              </TableCell>
                              <TableCell align="center">
                                {/* {pri.premium ? (
            <Check style={{ fontSize: 15, color: "#00ff00", fontWeight: "bold" }} />
          ) : (
            <Clear style={{ fontSize: 15, color: "#ff0000" }} />
          )} */}

                                {pri.premium === "Y" ? (
                                  <Check
                                    style={{
                                      fontSize: 20,
                                      color: "#00ff00",
                                      fontWeight: "bold",
                                    }}
                                  />
                                ) : pri.premium === "N" ? (
                                  <Clear
                                    style={{ fontSize: 20, color: "#ff0000" }}
                                  />
                                ) : pri.premium === "O" ? (
                                  <MultipleStopOutlined
                                    style={{ fontSize: 25, color: "#2748b4" }}
                                  />
                                ) : pri.premium ===
                                  "Extra charges may apply" ? (
                                  <Flaky
                                    style={{ fontSize: 20, color: "#2748b4" }}
                                  />
                                ) : null}
                              </TableCell>
                            </TableRow>
                          ) : null;
                        })
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            ))
          : null}
        <div style={{ height: 20 }}></div>
      </div>
      <Footer />
    </div>
  );
}

export default PricingDetails;
