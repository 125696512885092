import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
function Footer() {
  return (
    <div>
      <footer
        className="ud-footer wow fadeInUp"
        data-wow-delay=".15s"
        style={{ backgroundColor: "#055e9f", paddingTop: "20px" }}
      >
        <div className="shape shape-1">
          <img
            src="assets/images/footer/shape-1.svg"
            alt="shape"
            title="shape-1"
            loading="eager"
            width={"actualWidth"}
            height={"actualHeight"}
          />
        </div>
        <div className="shape shape-2">
          <img
            src="assets/images/footer/shape-2.svg"
            alt="shape"
            title="shape-2"
            loading="eager"
            width={"actualWidth"}
            height={"actualHeight"}
          />
        </div>
        <div className="shape shape-3">
          <img
            src="assets/images/footer/shape-3.svg"
            alt="shape"
            title="shape-3"
            loading="eager"
            width={"actualWidth"}
            height={"actualHeight"}
          />
        </div>
        <div className="ud-footer-widgets">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div className="ud-widget">
                  <a href="/" className="ud-footer-logo">
                    <img
                      src="assets/images/HHOA_logo2.png"
                      alt="HHOA_logo2.png"
                      title="HHOA_logo2.png"
                      loading="eager"
                      width={"actualWidth"}
                      height={"actualHeight"}
                    />
                  </a>
                  {/* <p className="ud-widget-desc">
                  We create digital experiences for brands and companies by
                  using technology.
                </p> */}
                  <div className="ud-widget">
                    {/* <h5 className="ud-widget-title">Downoad From</h5> */}
                    {/* <ul className="ud-widget-brands">
                  <li> */}
                    <div
                      className="ud-widget-brands"
                      style={{ marginTop: "-15px" }}
                    >
                      <a
                        href="https://play.google.com/store/apps/details?id=com.hybridhoa"
                        rel="nofollow noopner"
                        target="_blank"
                      >
                        <img
                          src="assets/images/footer/google play.png"
                          alt="ayroui"
                          id="google-play-img"
                          title="google-play"
                          loading="eager"
                          width={"actualWidth"}
                          height={"actualHeight"}
                          // height={60}  style={{backgroundColor:'red',width:'400px !important'}}
                        />
                      </a>
                    </div>
                    {/* </li><br/> */}
                    {/* <li style={{}}> */}
                    <div
                      className="ud-widget-brands"
                      style={{ marginTop: "-15px" }}
                    >
                      <a
                        href="https://apps.apple.com/us/app/hybridhoa/id6444210368"
                        rel="nofollow noopner"
                        target="_blank"
                      >
                        <img
                          src="assets/images/footer/appstore v2.png"
                          id="google-play-img"
                          alt="ecommerce-html"
                          title="appstore"
                          loading="eager"
                          width={"actualWidth"}
                          height={"actualHeight"}
                          //  style={{width:'500px'}}
                        />
                      </a>
                    </div>
                  </div>
                  <ul
                    className="ud-widget-socials"
                    style={{ marginBottom: "10px" }}
                  >
                    <li>
                      <a
                        href="https://www.facebook.com/people/Hybridhoa-Inc/100076220837614/"
                        target="_blank"
                      >
                        <i className="lni lni-facebook-filled"></i>
                        <span style={{ display: "none" }}>FaceBook</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/hybridhoainc"
                        target="_blank"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="1em"
                          viewBox="0 0 512 512"
                          style={{ fill: "#DDDDDD" }}
                        >
                          <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                        </svg>
                        <span style={{ display: "none" }}>Twitter</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/hybridhoa/"
                        target="_blank"
                      >
                        <i className="lni lni-instagram-filled"></i>
                        <span style={{ display: "none" }}>Instagram</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/81820257/admin/feed/posts/"
                        target="_blank"
                      >
                        <i className="lni lni-linkedin-original"></i>
                        <span style={{ display: "none" }}>Linkedin</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div className="ud-widget">
                  {/* <h5 className="ud-widget-title">About Us</h5> */}
                  <ul className="ud-widget-links">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>
                      <a href="/featuresdetail">Features</a>
                    </li>
                    <li>
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <a href="/vfbdetails">Views & Feedback</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div className="ud-widget">
                  {/* <h5 className="ud-widget-title">Features</h5> */}
                  <ul className="ud-widget-links">
                    <li>
                      {/* <Link to='/howitwork'>How it works</Link> */}
                      <a href="/howitwork">How it works</a>
                    </li>
                    <li>
                      <a href="https://blog.hybridhoa.com/">Blog</a>
                    </li>
                    {/* <li>
                   
                    <Link to='/privacypolicy' >Privacy Policy</Link>
                 
                  </li>
                  <li>
                    
                  <Link to='/termsandconditions' >Terms of service</Link>
                    
                  </li>
                  <li>
                    <a >Refund policy</a>
                  </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div className="ud-widget">
                  {/* <img src="https://www.hybridhoa.com/assets/img/logo_top.webp" style={{justifyContent:'center',textAlign:'center',marginTop:'100px'}}/> */}
                  {/* <h5 className="ud-widget-title">Our Products</h5> */}
                  <ul className="ud-widget-links">
                    <li>
                      <a href="/" rel="nofollow noopner" target="_blank">
                        HybridHOA
                      </a>
                    </li>
                    <li>
                      <a href="/hybridrenty" rel="nofollow noopner">
                        HybridRENTY
                      </a>
                    </li>
                    <li>
                      <a href="/hybridgatekeeper" rel="nofollow noopner">
                        HybridGATEKEEPER
                      </a>
                    </li>
                    <li>
                      <a href="/hybridhandyman" rel="nofollow noopner">
                        HybridHANDYMAN
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* <div className="col-xl-3 col-lg-6 col-md-8 col-sm-10">
              <div className="ud-widget"> */}
              {/* <img src="assets/images/hero/Footer.png"/> */}
              {/* <h5 className="ud-widget-title">Partners</h5>
                <ul className="ud-widget-brands">
                  <li>
                    <a
                      href="https://ayroui.com/"
                      rel="nofollow noopner"
                      target="_blank"
                    >
                      <img
                        src="https://www.hybridhoa.com/assets/img/playstore.webp"
                        alt="ayroui"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ecommercehtml.com/"
                      rel="nofollow noopner"
                      target="_blank"
                    >
                      <img
                        src="https://www.hybridhoa.com/assets/img/applestore.webp"
                        alt="ecommerce-html"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ecommercehtml.com/"
                      rel="nofollow noopner"
                      target="_blank"
                    >
                      <img
                        src="https://www.hybridhoa.com/assets/img/applestore.webp"
                        alt="ecommerce-html"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://graygrids.com/"
                      rel="nofollow noopner"
                      target="_blank"
                    >
                      <img
                        src=""
                        alt="graygrids"
                      />
                    </a>
                  </li>
                  <li style={{marginBottom:'20px'}}>
                    <a
                      href="https://lineicons.com/"
                      rel="nofollow noopner"
                      target="_blank"
                    >
                      <img
                        src="assets/images/footer/brands/lineicons.svg"
                        alt="lineicons"
                      />
                    </a>
                  </li>
                  <li style={{marginBottom:'20px'}}>
                    <a
                      href="https://uideck.com/"
                      rel="nofollow noopner"
                      target="_blank"
                    >
                      <img
                        src="assets/images/footer/brands/ayroui.svg"
                        alt="ayroui" 
                      />
                    </a>
                  </li>
                  <li style={{marginBottom:'20px'}}>
                    <a
                      href="https://tailwindtemplates.co/"
                      rel="nofollow noopner"
                      target="_blank"
                    >
                      <img
                        src="assets/images/footer/brands/tailwindtemplates.svg"
                        alt="tailwindtemplates"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <div
          className="ud-footer-bottom"
          style={{ padding: "5px", marginTop: "15px" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <ul className="ud-footer-bottom-left">
                  <li>
                    <a href="/privacypolicy">Privacy policy</a>
                  </li>
                  {/* <li>
                <Link to ="/privacypolicy">
                  Support policy
                  </Link>
                </li> */}
                  <li>
                    <a href="/termsofservice">Terms of service</a>
                  </li>
                  <li>
                    <a href="/datacollectionandsecurity">Data Collection & Security</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <p className="ud-footer-bottom-right">
                  © Copyright 2023. HybridHOA .All Rights Reserved
                  {/* <a href="https://quadprosoft.com/"></a> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import BlogLink from "./BlogLink";
// import { useEffect } from "react";
// function Footer() {

//   const location = useLocation();

//   useEffect(() => {
//     const storedRoute = sessionStorage.getItem('currentRoute');

//     const isPageRefresh = performance.navigation.type === performance.navigation.TYPE_RELOAD;
//     const isStoredRouteDifferent = storedRoute && storedRoute !== window.location.href;

//     if (isPageRefresh && isStoredRouteDifferent) {
//       window.location.href = storedRoute; // Redirect to the stored route on page refresh
//     }

//     const handleNavigation = () => {
//       sessionStorage.setItem('currentRoute', window.location.href);
//     };

//     // Add event listeners for navigation
//     window.addEventListener('beforeunload', handleNavigation);

//     // Remove event listeners on unmount
//     return () => {
//       window.removeEventListener('beforeunload', handleNavigation);
//     };
//   }, []);

//   const  isUsRoute = location.pathname.startsWith('/us');
//   const isInRoute=location.pathname.startsWith('/in');

//   return (
//     <div>
//       <footer
//         className="ud-footer wow fadeInUp"
//         data-wow-delay=".15s"
//         style={{ backgroundColor: "#055e9f", paddingTop: "20px" }}
//       >
//         <div className="shape shape-1" >
//           <img
//             src="assets/images/footer/shape-1.svg"
//             alt="shape"
//             title="shape-1"
//             loading="eager"
//             width={"actualWidth"}
//             height={"actualHeight"}
//           />
//         </div>
//         <div className="shape shape-2">
//           <img
//             src="assets/images/footer/shape-2.svg"
//             alt="shape"
//             title="shape-2"
//             loading="eager"
//             width={"actualWidth"}
//             height={"actualHeight"}
//           />
//         </div>
//         <div className="shape shape-3">
//           <img
//             src="assets/images/footer/shape-3.svg"
//             alt="shape"
//             title="shape-3"
//             loading="eager"
//             width={"actualWidth"}
//             height={"actualHeight"}
//           />
//         </div>
//         <div className="ud-footer-widgets">
//           <div className="container">
//             <div className="row">
//               <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
//                 <div className="ud-widget">
//                   {/* <a href="/" className="ud-footer-logo">
//                     <img
//                       src="assets/images/HHOA_logo2.png"
//                       alt="HHOA_logo2.png"
//                       title="HHOA_logo2.png"
//                       loading="eager"
//                       width={"actualWidth"}
//                       height={"actualHeight"}
//                     />
//                   </a> */}

// <Link to={isUsRoute ? '/us' : isInRoute ? '/in' : ''} className="navbar-brand">
//             <img
//         src={`${process.env.PUBLIC_URL}/assets/images/${isUsRoute ? 'HHOA_logo2.png' : isInRoute ? 'HHOA_logo4.png' : ''}`}
//         alt={isUsRoute ? 'HHOA_logo2.png' : isInRoute ? 'HHOA_logo4.png' : ''}
//         title={isUsRoute ? 'HHOA_logo2.png' : isInRoute ? 'HHOA_logo4.png' : ''}
//         loading="eager"
//         width={'actualWidth'}
//         height={'actualHeight'}
//       />
// </Link>
//                   {/* <p className="ud-widget-desc">
//                   We create digital experiences for brands and companies by
//                   using technology.
//                 </p> */}
//                   <div className="ud-widget">
//                     {/* <h5 className="ud-widget-title">Downoad From</h5> */}
//                     {/* <ul className="ud-widget-brands">
//                   <li> */}
//                     <div
//                       className="ud-widget-brands"
//                       style={{ marginTop: "-15px" }}
//                     >
//                       <a
//                         href="https://play.google.com/store/apps/details?id=com.hybridhoa"
//                         rel="nofollow noopner"
//                         target="_blank"
//                       >
//                         <img
//                           src="assets/images/footer/google play.png"
//                           alt="ayroui"
//                           id="google-play-img"
//                           title="google-play"
//                           loading="eager"
//                           width={"actualWidth"}
//                           height={"actualHeight"}
//                           // height={60}  style={{backgroundColor:'red',width:'400px !important'}}
//                         />
//                       </a>
//                     </div>
//                     {/* </li><br/> */}
//                     {/* <li style={{}}> */}
//                     <div
//                       className="ud-widget-brands"
//                       style={{ marginTop: "-15px" }}
//                     >
//                       <a
//                         href="https://apps.apple.com/us/app/hybridhoa/id6444210368"
//                         rel="nofollow noopner"
//                         target="_blank"
//                       >
//                         <img
//                           src="assets/images/footer/appstore v2.png"
//                           id="google-play-img"
//                           alt="ecommerce-html"
//                           title="appstore"
//                           loading="eager"
//                           width={"actualWidth"}
//                           height={"actualHeight"}
//                           //  style={{width:'500px'}}
//                         />
//                       </a>
//                     </div>
//                   </div>
//                   <ul
//                     className="ud-widget-socials"
//                     style={{ marginBottom: "5px" }}
//                   >
//                     <li>
//                       <a
//                         href="https://www.facebook.com/people/Hybridhoa-Inc/100076220837614/"
//                         target="_blank"
//                       >
//                         <i className="lni lni-facebook-filled"></i>
//                         <span style={{ display: "none" }}>FaceBook</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://twitter.com/hybridhoainc"
//                         target="_blank"
//                       >
//                         <svg
//                           xmlns="http://www.w3.org/2000/svg"
//                           height="1em"
//                           viewBox="0 0 512 512"
//                           style={{ fill: "#DDDDDD" }}
//                         >
//                           <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
//                         </svg>
//                         <span style={{ display: "none" }}>Twitter</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://www.instagram.com/hybridhoa/"
//                         target="_blank"
//                       >
//                         <i className="lni lni-instagram-filled"></i>
//                         <span style={{ display: "none" }}>Instagram</span>
//                       </a>
//                     </li>
//                     <li>
//                       <a
//                         href="https://www.linkedin.com/in/mohanram-cirumyloor-10666b222/"
//                         target="_blank"
//                       >
//                         <i className="lni lni-linkedin-original"></i>
//                         <span style={{ display: "none" }}>Linkedin</span>
//                       </a>
//                     </li>
//                   </ul>
//                 </div>
//               </div>

//               <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
//                 <div className="ud-widget">
//                   {/* <h5 className="ud-widget-title">About Us</h5> */}
//                   <ul className="ud-widget-links">
//                     <li>
//                       {/* <a href="/">Home</a> */}
//                       <Link to={isUsRoute ? '/us' : isInRoute ? '/in' : ''} className="ud-menu-scroll">Home</Link>
//                     </li>
//                     <li>
//                       {/* <a href="/featuresdetail">Features</a> */}
//                       <Link to={isUsRoute ? '/us/featuresdetail' : isInRoute ? '/in/featuresdetail' : ''} className="ud-menu-scroll">Features</Link>
//                     </li>
//                     <li>
//                       {/* <a href="/about">About</a> */}
//                       <Link to={isUsRoute ? '/us/about' : isInRoute ? '/in/about' : ''} className="ud-menu-scroll">About</Link>
//                     </li>
//                     <li>
//                       {/* <a href="/vfbdetails">Views & Feedback</a> */}
//                       <Link to={isUsRoute ? '/us/vfbdetails' : isInRoute ? '/in/vfbdetails' : ''} className="ud-menu-scroll">Views & Feedback</Link>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
//                 <div className="ud-widget">
//                   {/* <h5 className="ud-widget-title">Features</h5> */}
//                   <ul className="ud-widget-links">
//                     <li>
//                       {/* <Link to='/howitwork'>How it works</Link> */}
//                       {/* <a href="/howitwork">How it works</a> */}
//                       <Link to={isUsRoute ? '/us/howitwork' : isInRoute ? '/in/howitwork' : ''} className="ud-menu-scroll">How it works</Link>
//                     </li>
//                     <li>
//                       {/* <a href="https://blog.hybridhoa.com/">Blog</a> */}
//                       <BlogLink to ={isUsRoute ? 'https://blog.hybridhoa.com/us' :isInRoute ? 'https://blog.hybridhoa.com/in' : ''}  className="ud-submenu-item" >
//   Blog
// </BlogLink>
//                     </li>
//                     {/* <li>

//                     <Link to='/privacypolicy' >Privacy Policy</Link>

//                   </li>
//                   <li>

//                   <Link to='/termsofservice' >Terms of service</Link>

//                   </li>
//                   <li>
//                     <a >Refund policy</a>
//                   </li> */}
//                   </ul>
//                 </div>
//               </div>
//               <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12">
//                 <div className="ud-widget">
//                   {/* <img src="https://www.hybridhoa.com/assets/img/logo_top.webp" style={{justifyContent:'center',textAlign:'center',marginTop:'100px'}}/> */}
//                   {/* <h5 className="ud-widget-title">Our Products</h5> */}
//                   <ul className="ud-widget-links">
//                     <li>
//                       {/* <a href="/" rel="nofollow noopner" target="_blank">
//                         HybridHOA
//                       </a> */}
//                       <Link to={isUsRoute ? '/us' : isInRoute ? '/in' : ''} className="ud-menu-scroll"> HybridHOA</Link>
//                     </li>
//                     <li>
//                       {/* <a href="/hybridrenty" rel="nofollow noopner">
//                         HybridRENTY
//                       </a> */}
//                       <Link to={isUsRoute ? '/us/hybridrenty' : isInRoute ? '/in/hybridrenty' : ''} className="ud-menu-scroll"> HybridRENTY</Link>
//                     </li>
//                     <li>
//                       {/* <a href="/hybridgatekeeper" rel="nofollow noopner">
//                         HybridGATEKEEPER
//                       </a> */}
//                        <Link to={isUsRoute ? '/us/hybridgatekeeper' : isInRoute ? '/in/hybridgatekeeper' : ''} className="ud-menu-scroll"> HybridGATEKEEPER</Link>
//                     </li>
//                     <li>
//                       {/* <a href="/hybridhandyman" rel="nofollow noopner">
//                         HybridHANDYMAN
//                       </a> */}
//                        <Link to={isUsRoute ? '/us/hybridhandyman' : isInRoute ? '/in/hybridhandyman' : ''} className="ud-menu-scroll"> HybridHANDYMAN</Link>
//                     </li>
//                   </ul>
//                 </div>
//               </div>
//               {/* <div className="col-xl-3 col-lg-6 col-md-8 col-sm-10">
//               <div className="ud-widget"> */}
//               {/* <img src="assets/images/hero/Footer.png"/> */}
//               {/* <h5 className="ud-widget-title">Partners</h5>
//                 <ul className="ud-widget-brands">
//                   <li>
//                     <a
//                       href="https://ayroui.com/"
//                       rel="nofollow noopner"
//                       target="_blank"
//                     >
//                       <img
//                         src="https://www.hybridhoa.com/assets/img/playstore.webp"
//                         alt="ayroui"
//                       />
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="https://ecommercehtml.com/"
//                       rel="nofollow noopner"
//                       target="_blank"
//                     >
//                       <img
//                         src="https://www.hybridhoa.com/assets/img/applestore.webp"
//                         alt="ecommerce-html"
//                       />
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="https://ecommercehtml.com/"
//                       rel="nofollow noopner"
//                       target="_blank"
//                     >
//                       <img
//                         src="https://www.hybridhoa.com/assets/img/applestore.webp"
//                         alt="ecommerce-html"
//                       />
//                     </a>
//                   </li>
//                   <li>
//                     <a
//                       href="https://graygrids.com/"
//                       rel="nofollow noopner"
//                       target="_blank"
//                     >
//                       <img
//                         src=""
//                         alt="graygrids"
//                       />
//                     </a>
//                   </li>
//                   <li style={{marginBottom:'20px'}}>
//                     <a
//                       href="https://lineicons.com/"
//                       rel="nofollow noopner"
//                       target="_blank"
//                     >
//                       <img
//                         src="assets/images/footer/brands/lineicons.svg"
//                         alt="lineicons"
//                       />
//                     </a>
//                   </li>
//                   <li style={{marginBottom:'20px'}}>
//                     <a
//                       href="https://uideck.com/"
//                       rel="nofollow noopner"
//                       target="_blank"
//                     >
//                       <img
//                         src="assets/images/footer/brands/ayroui.svg"
//                         alt="ayroui"
//                       />
//                     </a>
//                   </li>
//                   <li style={{marginBottom:'20px'}}>
//                     <a
//                       href="https://tailwindtemplates.co/"
//                       rel="nofollow noopner"
//                       target="_blank"
//                     >
//                       <img
//                         src="assets/images/footer/brands/tailwindtemplates.svg"
//                         alt="tailwindtemplates"
//                       />
//                     </a>
//                   </li>
//                 </ul>
//               </div>
//             </div> */}
//             </div>
//           </div>
//         </div>
//         <div
//           className="ud-footer-bottom"
//           style={{ padding: "5px", marginTop: "15px" }}
//         >
//           <div className="container">
//             <div className="row">
//               <div className="col-md-6">
//                 <ul className="ud-footer-bottom-left">
//                   <li>
//                     {/* <a href="/privacypolicy">Privacy policy</a> */}
//                     <Link to ={isUsRoute ? '/us/privacypolicy' :isInRoute ? '/in/privacypolicy' : ''}className="ud-submenu-link" replace >Privacy policy</Link>
//                   </li>
//                   {/* <li>
//                 <Link to ="/privacypolicy">
//                   Support policy
//                   </Link>
//                 </li> */}
//                   <li>
//                     {/* <a href="/termsofservice">Terms of service</a> */}
//                     <Link to ={isUsRoute ? '/us/termsofservice' :isInRoute ? '/in/termsofservice' : ''}className="ud-submenu-link" replace >Terms of service</Link>
//                   </li>
//                 </ul>
//               </div>
//               <div className="col-md-6">
//                 <p className="ud-footer-bottom-right">
//                   © Copyright 2023. HybridHOA .All Rights Reserved
//                   {/* <a href="https://quadprosoft.com/"></a> */}
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// }

// export default Footer;
