import { Divider } from '@mui/material'
import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';

function SeparatePageHeader(props) {
  const location = useLocation();

  const  isUsRoute = location.pathname.startsWith('/us');
  const isInRoute=location.pathname.startsWith('/in');

  return (
    <div>
      <section className="ud-page-banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-banner-content">
              <h1>{props.title}</h1>
            </div>
          </div>
        </div>
        <br/>
        
        <div className='row'>
            <div className="col-lg-3"></div>
        <div className="col-lg-9">
        <div className="ud-banner-content">
        {/* <Breadcrumb >
      <Breadcrumb.Item href={isUsRoute ? '/us#/us' : isInRoute ? '/in#/in' : ''} ><p style={{color:'white'}}>Home</p></Breadcrumb.Item>
      <Breadcrumb.Item href={isUsRoute ? '/us#/us/privacypolicy' : isInRoute ? '/in#/in/privacypolicy' : ''}>
      <p style={{color:'white'}}>Privacy Policy</p>
      </Breadcrumb.Item>
      <Breadcrumb.Item href={isUsRoute ? '/us#/us/termsofservice' : isInRoute ? '/in#/in/termsofservice' : ''}><p style={{color:'white'}}>Terms of service</p></Breadcrumb.Item>
      <Breadcrumb.Item href={isUsRoute ? '/us#/us/datacollectionandsecurity' : isInRoute ? '/in#/in/datacollectionandsecurity' : ''}><p style={{color:'white'}}>Data Collection & Security</p></Breadcrumb.Item>
    </Breadcrumb> */}
    
        </div>
                  </div>
        </div>
      </div>
      <Divider style={{color:'white'}}></Divider>
    </section>
    </div>
  )
}

export default SeparatePageHeader