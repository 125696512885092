import React,{useState,useEffect, useCallback} from 'react'
import axios from 'axios';
import { BASE_URL, MAINFEATURE } from '../utils/ApplicationEndpoints'


function HomeFeatureMain(props) {

    const [topic,SetTopic] = useState('')
    const [description,setDescription]= useState('')


    
  const fetchData = useCallback(async () => {
    console.log("fetch data ");
    try {
      const response = await axios.get(BASE_URL+ MAINFEATURE);
      console.log(response);

      SetTopic(response.data[0].topic)
      setDescription(response.data[0].description)
      console.log("testttttttt"+response.data[0].topic);

    } catch (error) { 
      console.log(error);
    }
  }, []);

  useEffect(() => {
   fetchData()
  }, [fetchData])
  return (
    <div>
      <br/><br/>
       <section style={{ textAlign: "justify" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ud-section-title mx-auto text-center">
                {/* <span style={{ fontSize: "30px" }}>{props.title}</span> */}
   {/* modified by mohan raj for HB-XXXX on 25-08-23 start */}

                {/* <h1>{topic}</h1> */}
                {/* modified by mohan raj for HB-XXXX on 25-08-23 end */}

                   {/* modified by mohan raj for HB-XXXX on 29-08-23 start */}

                <h1 style={{ color: '#363636' }}>{topic}</h1>
                   {/* modified by mohan raj for HB-XXXX on 29-08-23 end */}


   

                <p>
                 {description}
                </p>
              </div>
            </div>
          </div>
        </div>
       </section>
    </div>
  )
}

export default HomeFeatureMain
