import React, { useEffect, useState } from "react";
import {  useParams, useLocation } from "react-router-dom";


import PageHeader from "../Components/PageHeader";
import axios from "axios";
import {
  BASE_URL,
  BLOG,
  
  IMAGE,
  SUBSCRIBE,
} from "../utils/ApplicationEndpoints";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import Swal from "sweetalert2";
import "./BlogDetail.css";
import $ from "jquery";
import BlogHeader from "../Components/BlogHeader";
import { Helmet } from "react-helmet";
import {
  FacebookShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  WhatsappIcon,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

function BlogDetails({ props }) {
  let { state } = useLocation();
  useEffect(() => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");
    const udMenuScrollLinks = document.querySelectorAll(".ud-menu-scroll");
    const submenuButtons = document.querySelectorAll(".nav-item-has-children");

    udMenuScrollLinks.forEach((link) => {
      link.addEventListener("click", () => {
        navbarToggler.classList.remove("active");
        navbarCollapse.classList.remove("show");
      });
    });

    navbarToggler.addEventListener("click", () => {
      navbarToggler.classList.toggle("active");
      navbarCollapse.classList.toggle("show");
    });

    submenuButtons.forEach((button) => {
      button.querySelector("a").addEventListener("click", () => {
        button.querySelector(".ud-submenu").classList.toggle("show");
      });
    });

    // Remove event listeners on unmount
    return () => {
      udMenuScrollLinks.forEach((link) => {
        link.removeEventListener("click", () => {
          navbarToggler.classList.remove("active");
          navbarCollapse.classList.remove("show");
        });
      });

      navbarToggler.removeEventListener("click", () => {
        navbarToggler.classList.toggle("active");
        navbarCollapse.classList.toggle("show");
      });

      submenuButtons.forEach((button) => {
        button.querySelector("a").removeEventListener("click", () => {
          button.querySelector(".ud-submenu").classList.toggle("show");
        });
      });
    };
  }, []); // Run only once on mount

  window.onscroll = function () {
    const ud_header = document.querySelector(".ud-header");
    const sticky = ud_header.offsetTop;
    const logo = document.querySelector(".navbar-brand img");
    const button = $(".ud-main-btns");

    if (window.pageYOffset > sticky) {
      ud_header.classList.add("sticky");
      button.css({ "background-color": "#2748b4", color: "white" });
    } else {
      ud_header.classList.remove("sticky");
      button.css({ "background-color": "white", color: "#2748b4" });
    }

    if (ud_header.classList.contains("sticky")) {
      logo.src = "https://hybridhoa.com/assets/images/HHOA_logo1.png";
    } else {
      logo.src = "https://hybridhoa.com/assets/images/HHOA_logo2.png";
    }
  };

  const [data, setData] = useState([]);
  const [alt, setAlt] = useState("");
  const [imageData, setImageData] = useState("");
  const [blogTopic,setBlogTopic] = useState("");
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
 



  useEffect(() => {
    // BlogDetailsById();
    window.scrollTo(0, 0)
    setBlogTopic(state.topic);
    setData(state.data);
    setImageData(state.imageUrl);
    setAlt(state.imgAlternative);
    console.log(state);
  }, [state]);

  useEffect(() => {
    axios
      .get(BASE_URL + BLOG)
      .then((res) => {
        console.log("Data:", res.data[0].data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = (data) => {
    console.log("clicked submit");
    if (!data.email) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Email is required",
      });
      return;
    }
    const emailPattern =
      /^(?!.*\.com.*\.com.*\.com)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(data.email)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid email format",
      });
      return;
    }

    axios
      .post(BASE_URL + SUBSCRIBE, {
        email: data.email,
      })
      .then((res) => {
        console.log(res.status);

        if (res.status === 200 || res.status === 201) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "We received your query. We will get in touch shortly.",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const extractHeadingFromHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const heading = doc.querySelector("h4");
    return heading ? heading.textContent.trim() : ""; // Return empty string if no <h4> tag is found
  };

  // Function to extract text from all <p> tags in HTML
  const extractParagraphsFromHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const paragraphs = doc.querySelectorAll("p");
    let text = "";
    paragraphs.forEach((p) => {
      text += p.textContent + " "; // Separate paragraphs with space
    });
    return text.trim();
  };


  const shareUrl = `https://hybridhoa.com/blogdetail/${id}`; // Update 'id' with the actual blog post id
  const title = extractHeadingFromHTML(data); // Extract text from the first <h4> tag in the 'data'
  const description = extractParagraphsFromHTML(data); // Use the dynamic meta description as the share description
  const imageUrl = `${BASE_URL}${IMAGE}${imageData}`;
  const hashtag =
    "#propertymanagement #associations #propertymanagementsoftware #communityassociation #community #hybridhoa #homeownerassociations #hoa";
  return (
    <div>
    <Helmet>
        <title>{`Blog | ${title}`}</title>
        <meta name="title" content={`Blog | ${title}`} />
        <meta name="description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={shareUrl} />
        <meta property="og:title" content={`Blog | ${title}`} />
        <meta property="og:description" content={description} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={shareUrl} />
        <meta property="twitter:title" content={`Blog | ${title}`} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={imageUrl} />

        <link rel='canonical' href='https://www.hybridhoa.com/blogdetail' />

      </Helmet>
      <div>
        <BlogHeader />
        <PageHeader title="Topics of interest..." />
        <section className="ud-blog-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div>
                  <img src={`${BASE_URL}${IMAGE}${imageData}`} alt={alt} />
                </div>
                <FacebookShareButton url={shareUrl} quote={description} hashtag={hashtag}>
                <FacebookIcon size={40} round={true} />
              </FacebookShareButton>
              <WhatsappShareButton url={shareUrl} quote={description} hashtag={hashtag}>
                <WhatsappIcon size={40} round={true} />
              </WhatsappShareButton>
              <LinkedinShareButton url={shareUrl} quote={description} hashtag={hashtag}>
                <LinkedinIcon size={40} round={true} />
              </LinkedinShareButton>
              <TwitterShareButton url={shareUrl} quote={description} hashtag={hashtag}>
                <TwitterIcon size={40} round={true} />
              </TwitterShareButton>
              </div>

              <div className="col-lg-8">
                <div className="ud-blog-details-content">
                  <h3>{blogTopic}</h3>
                  <br></br>
                  <div dangerouslySetInnerHTML={{ __html: data }}></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ud-blog-sidebar">
                  <div className="ud-newsletter-box">
                    <h2 className="ud-newsletter-title">
                      Join our newsletter!
                    </h2>
                    <p>Enter your email to receive our latest newsletter.</p>
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      style={{ color: "white" }}
                    >
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        validate={(value) => value.trim()}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            {...field}
                            label="Enter your Email*"
                            style={{ backgroundColor: "white" }}
                            error={Boolean(errors?.email?.message)}
                            variant="standard"
                            helperText={errors?.email?.message}
                          />
                        )}
                      />
                      <br />
                      <br />
                      <br />
                      <Button
                        className="ud-main-btn"
                        type="submit"
                        variant="contained"
                      >
                        Subscribe Now!
                      </Button>
                      <p className="ud-newsletter-note">
                        Don't worry, we don't spam
                      </p>
                    </form>
                  </div>

                  {/* <div className="ud-articles-box">
                    <h3 className="ud-articles-box-title">Popular</h3>
                    <ul className="ud-articles-list">
                      {blogData &&
                        blogData.map((item, index) => (
                          <li key={index}>
                            <div className="ud-article-image">
                              <Link to={`/blogdetail/${item.id}`}>
                                {Array.isArray(item.imageUrl) ? (
                                  item.imageUrl.map((img, index) => (
                                    <img
                                      key={index}
                                      src={`${BASE_URL}${IMAGE}${img}`}
                                      alt="blog"
                                      style={{
                                        alignContent: "center",
                                        alignItems: "center",
                                      }}
                                    />
                                  ))
                                ) : (
                                  <img
                                    src={`${BASE_URL}${IMAGE}${item.imageUrl}`}
                                    alt="blog"
                                    style={{
                                      alignContent: "center",
                                      alignItems: "center",
                                    }}
                                  />
                                )}
                              </Link>
                            </div>
                          
                            <div className="ud-article-content">
                              <h3 className="ud-article-title">
                                <div
                                  className="ud-blog-content"
                                  dangerouslySetInnerHTML={{
                                    __html: item.data,
                                  }}
                                ></div>
                              </h3>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default BlogDetails;
