import Header from "../Components/Header";
import {
  Button,
  Card,

  CardContent,

  
  Typography,
  
} from "@mui/material";
import {
  BASE_URL,
  PRICINGCONTENT,
  PRICINGHERO,
} from "../utils/ApplicationEndpoints";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link,  useLocation } from "react-router-dom";

import Switch from '@mui/material/Switch';

import bluearrow from '../Images/savings-arrow.svg'
import { ArrowForward } from '@mui/icons-material';




function HomePagePricing(props) {
  const [data, setData] = useState([]);

 
 // <----------------------toggle switch---------------------------->
 const [isToggled, setToggled] = useState(false);

 const handleToggle = () => {
   const newState = !isToggled;
   setToggled(newState);
  //  console.log(newState);
 };

  const anchorStyle = {
    fontSize: '16px', 
    color:'gray',
   
  };
  

  
    const cardHoverStyle = {
      transform: 'scale(1.02, 1.02)',
      WebkitTransform: 'scale(1.02, 1.02)',
      backfaceVisibility: 'hidden',
      willChange: 'transform',
      boxShadow: '0 1rem 3rem rgba(221, 218, 218, 1) !important',
    };
    

    const savecolor = {
      marginLeft: '-30px',
      marginBottom: '275px',
      color: '#0070E0',
      position: 'absolute',
      marginTop: '-50px',
      fontSize:'15px',
    };
    
    const arrowposition ={
      marginBottom:'20px',
      // filter: 'hue-rotate(240deg)',
      // transform: 'rotate(160deg)',
    }

    const aligncenter = {
      marginRight:'-50px',
     }
 

  
  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + PRICINGHERO);
      setData(response.data);
      // console.log(data);
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);



 
  const fetchDatapricing = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + PRICINGCONTENT);
      const pricingData = response.data;
      pricingData.sort((a, b) => a.pricingOrder - b.pricingOrder);
      // console.log("testttttttttttttttttttttttttttt" + JSON.stringify(pricingData));

      console.log(
        "testttttttttttttttttttttttttttt" + JSON.stringify(pricingData)
      );


      const uniqueCategories = pricingData.reduce((unique, item) => {
        if (!unique.includes(item.category)) {
          unique.push(item.category);
        }
        return unique;
      }, []);
      uniqueCategories.sort((a, b) => {
        const categoryA = pricingData.find((item) => item.category === a);
        const categoryB = pricingData.find((item) => item.category === b);
        return categoryA.categoryOrder - categoryB.categoryOrder;
      });

    } catch (error) {
      console.error("Error fetching pricing data:", error);
    }
  }, []);

  useEffect(() => {
    fetchDatapricing();
  }, []);

  const location = useLocation();

  const  isUsRoute = location.pathname.startsWith('/us');
  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
     
      <Header />
      {/* <PageHeader title="Our Pricing Options for better management" /> */}
      &nbsp;

      {/* <div className="row" >
     

      <Switch style={switchStyle}{...label} />
      <Switch style={switchStyle} />
     
    </div>    */}
     
   
    <div className="text-center" style={aligncenter} >
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <span style={{ fontWeight: !isToggled ? 'bold' : 'normal' }}>Pay Monthly</span>
        <Switch checked={isToggled} onClick={handleToggle} />
        <span style={{ fontWeight: isToggled ? 'bold' : 'normal' }}>
          Pay Yearly &nbsp;
        </span> 
        
        <div>
    <img src={bluearrow} style={arrowposition}   width="30" 
        height="30" alt="" />

    </div>

    <div> 
      <span ><a style={savecolor}>Save 10%</a></span></div>
      </div>

    </div> &nbsp;
      <div className="container">
        
        {data.length > 0 ? (
          <div className="row">
            
            <div className="col-lg-4 col-md-12 mb-4">
      <Card
        sx={{
          height: '95%',
          width: '75%',
          position: 'relative',
          '&:hover': cardHoverStyle,
          '&:hover::after': { opacity: 1 },
          borderRadius: '10px',
          left: '15%',
          '@media (max-width: 768px)': {
            // Adjust styles for screens smaller than 768px
            width: '100%',
            left: '0%',
          },
        }}
      >
        <CardContent>
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            <Typography variant="h5" component="div" gutterBottom>
              <h2>{data[0].topic}</h2>
            </Typography>

            <Typography>
              <span style={{ fontSize: '15px' }}>Starts From</span>
            </Typography>

            <Typography variant="h4" style={{ marginTop: '10px' }}>
              {isToggled ? (
                <p>
                  <h3>
                    $ {data[0].amount - 0.1 * data[0].amount}
                    <a style={anchorStyle}> / unit</a>
                  </h3>
                </p>
              ) : (
                <p>
                  <h3>
                    ${data[0].amount}
                    <a style={anchorStyle}> / unit</a>
                  </h3>
                </p>
              )}
            </Typography>
            <br />
          </div>
        </CardContent>

        <div className="card-body text-center">
          <Button
            variant="outlined"
            onClick={() => {
              window.open(data[0].buttonUrl, '_blank');
            }}
            sx={{
              position: 'absolute',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 200,
             
            }}
          >
            Schedule a Demo
          </Button>
        </div>
      </Card>
    </div>



    <div className="col-lg-4 col-md-12 mb-4">
      <Card
        sx={{
          height: '95%',
          width: '75%',
          position: 'relative',
          '&:hover': cardHoverStyle,
          '&:hover::after': { opacity: 1 },
          borderRadius: '10px',
          left: '15%',
          '@media (max-width: 768px)': {
            // Adjust styles for screens smaller than 768px
            width: '100%',
            left: '0%',
          },
        }}
      >
        <CardContent>
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            <Typography variant="h5" component="div" gutterBottom>
              <h2>{data[1].topic}</h2>
            </Typography>

            <Typography>
              <span style={{ fontSize: '15px' }}>Starts From</span>
            </Typography>

            <Typography variant="h4" style={{ marginTop: '10px' }}>
              {isToggled ? (
                <p>
                  <h3>
                    $ {data[1].amount - 0.1 * data[1].amount}
                    <a style={anchorStyle}> / unit</a>
                  </h3>
                </p>
              ) : (
                <p>
                  <h3>
                    ${data[1].amount}
                    <a  style={anchorStyle}> / unit</a>
                  </h3>
                </p>
              )}
            </Typography>
            <br />
          </div>
        </CardContent>

      
        <div className="card-body text-center">
        <Button
                      variant="contained"
                      onClick={() => {
                        window.open(data[1].buttonUrl, "_blank");
                      }}
                      sx={{ 
                        position: 'absolute',
                        bottom: 20,
                        left: '50%', 
                        transform: 'translateX(-50%)',
                         
                        width:200,
                       
                      }}
                     
                    >
                      Schedule a Demo
                    </Button>
        </div>
      </Card>
    </div>



    <div className="col-lg-4 col-md-12 mb-4">
      <Card
        sx={{
          height: '95%',
          width: '75%',
          position: 'relative',
          '&:hover': cardHoverStyle,
          '&:hover::after': { opacity: 1 },
          borderRadius: '10px',
          left: '15%',
          '@media (max-width: 768px)': {
            // Adjust styles for screens smaller than 768px
            width: '100%',
            left: '0%',
          },
        }}
      >
        <CardContent>
          <div style={{ textAlign: 'center', padding: '0.5rem' }}>
            <Typography variant="h5" component="div" gutterBottom>
              <h2>{data[2].topic}</h2>
            </Typography>

            <Typography>
              <span style={{ fontSize: '15px' }}>Starts From</span>
            </Typography>

            <Typography variant="h4" style={{ marginTop: '10px' }}>
              {isToggled ? (
                <p>
                  <h3>
                    $ {data[2].amount - 0.1 * data[2].amount}
                    <a  style={anchorStyle}> / unit</a>
                  </h3>
                </p>
              ) : (
                <p>
                  <h3>
                    ${data[2].amount}
                    <a style={anchorStyle}> / unit</a>
                  </h3>
                </p>
              )}
            </Typography>
            <br />
          </div>
        </CardContent>

        <div className="card-body text-center">
          <Button
            variant="outlined"
            onClick={() => {
              window.open(data[2].buttonUrl, '_blank');
            }}
            sx={{
              position: 'absolute',
              bottom: 20,
              left: '50%',
              transform: 'translateX(-50%)',
              width: 200,
             
            }}
          >
            Schedule a Demo
          </Button>
        </div>
      </Card>
    </div>
          
          </div>
        ) : null}
        {props.readmore ? 
     <div className="ud-about-content-wrapper ud-about-contents  pt-2 pb-2" style={{textAlign:'center'}}>
      <div >
      <Link to={ '/pricing'} className='ud-about-content'>Read More <ArrowForward/> </Link>
        </div>
    
   </div> : null}

      </div>
    </div>
  );
}

export default HomePagePricing;
