import $ from 'jquery';

(function () {
  "use strict";

  // ======= Sticky
  window.onscroll = function () {
    const ud_header = document.querySelector(".ud-header");
    const sticky = ud_header.offsetTop;
    const logo = document.querySelector(".navbar-brand img");
    var button = $(".ud-main-btns");


    if (window.pageYOffset > sticky) {
      ud_header.classList.add("sticky");
      button.css({"background-color": "#2748b4", "color": "white"});
    } else {
      ud_header.classList.remove("sticky");
      button.css({"background-color": "white", "color": "#2748b4"});
    }


    // === logo change
    // if (ud_header.classList.contains("sticky")) {
    //   logo.src = "./assets/images/hybridhoalogo1.png";
    // } else {
    //   logo.src = "./assets/images/hero/Footer.png"
    // }
    if (ud_header.classList.contains("sticky")) {
      logo.src = "./assets/images/HHOA_logo1.png";
      logo.alt="HHOA_logo1.png";
      logo.title="HHOA_logo1.png";
      logo.loading = "eager";
    } else {
      logo.src = "./assets/images/HHOA_logo2.png";
      logo.alt="HHOA_logo2.png";
      logo.title = "HHOA_logo2.png";
      logo.loading = "eager";
    }


    // show or hide the back-top-top button
    const backToTop = document.querySelector(".back-to-top");
    if (backToTop !== null) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        backToTop.style.display = "flex";
      } else {
        backToTop.style.display = "none";
      }
    }
  };


  // document.addEventListener("DOMContentLoaded", () => {
  //   const navbarToggler = document.querySelector(".navbar-toggler");
  //   const navbarCollapse = document.querySelector(".navbar-collapse");
  //   const udMenuScrollLinks = document.querySelectorAll(".ud-menu-scroll");
  //   const submenuButtons = document.querySelectorAll(".nav-item-has-children");
  
  //   udMenuScrollLinks.forEach(link => {
  //     link.addEventListener("click", () => {
  //       navbarToggler.classList.remove("active");
  //       navbarCollapse.classList.remove("show");
  //     });
  //   });
  
  //   navbarToggler.addEventListener("click", () => {
  //     navbarToggler.classList.toggle("active");
  //     navbarCollapse.classList.toggle("show");
  //   });
  
  //   submenuButtons.forEach(button => {
  //     button.querySelector("a").addEventListener("click", () => {
  //       button.querySelector(".ud-submenu").classList.toggle("show");
  //     });
  //   });
  // });
  
  


  // ===== wow js
  // new WOW().init();


  // ====== scroll top js
  function scrollTo(element, to = 0, duration = 500) {
    const start = element.scrollTop;
    const change = to - start;
    const increment = 20;
    let currentTime = 0;


    const animateScroll = () => {
      currentTime += increment;


      const val = Math.easeInOutQuad(currentTime, start, change, duration);


      element.scrollTop = val;


      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };


    animateScroll();
  }


  Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };


  // document.querySelector(".back-to-top").onclick = () => {
  //   scrollTo(document.documentElement);
  // };
})();

// import $ from 'jquery';

// (function () {
  

//   // ======= Sticky
//   window.onscroll = function () {
//     const ud_header = document.querySelector(".ud-header");
//     const sticky = ud_header.offsetTop;
//     const logo = document.querySelector(".navbar-brand img");
//     var button = $(".ud-main-btns");


//     if (window.pageYOffset > sticky) {
//       ud_header.classList.add("sticky");
//       button.css({"background-color": "#2748b4", "color": "white"});
//     } else {
//       ud_header.classList.remove("sticky");
//       button.css({"background-color": "white", "color": "#2748b4"});
//     }


//     // === logo change
//     if (ud_header.classList.contains("sticky")) {
//       logo.src = "./assets/images/hybridhoalogo1.png";
//     } else {
//       logo.src = "./assets/images/hero/Footer.png"
//     }
//     // if (ud_header.classList.contains("sticky")) {
//     //   logo.src = `${process.env.PUBLIC_URL}/assets/images/HHOA_logo1.png`;
//     //   logo.alt="HHOA_logo1.png";
//     //   logo.title="HHOA_logo1.png";
//     //   logo.loading = "eager";
//     // } else {
//     //   logo.src = `${process.env.PUBLIC_URL}/assets/images/HHOA_logo2.png`;
//     //   logo.alt="HHOA_logo2.png";
//     //   logo.title = "HHOA_logo2.png";
//     //   logo.loading = "eager";
//     // }

//     if (ud_header.classList.contains("sticky")) {
//       if (window.location.hash.startsWith("#/us")) {
//         logo.src = `${process.env.PUBLIC_URL}/assets/images/HHOA_logo1.png`;
//         logo.alt = "HHOA_logo1.png";
//         logo.title = "HHOA_logo1.png";
//         logo.loading = "eager";
//       } else if (window.location.hash.startsWith("#/in")) {
//         logo.src = `${process.env.PUBLIC_URL}/assets/images/HHOA_logo3.png`;
//         logo.alt = "HHOA_logo3.png";
//         logo.title = "HHOA_logo3.png";
//         logo.loading = "eager";
//       }
//     } else {
//       if (window.location.hash.startsWith("#/us")) {
//         logo.src = `${process.env.PUBLIC_URL}/assets/images/HHOA_logo2.png`;
//         logo.alt = "HHOA_logo2.png";
//         logo.title = "HHOA_logo2.png";
//         logo.loading = "eager";
//       } else if (window.location.hash.startsWith("#/in")) {
//         logo.src = `${process.env.PUBLIC_URL}/assets/images/HHOA_logo4.png`;
//         logo.alt = "HHOA_logo4.png"; 
//         logo.title = "HHOA_logo4.png";
//         logo.loading = "eager";
//       }
//     }
    
    
//     // logo.loading = "eager";

//     // show or hide the back-top-top button
//     const backToTop = document.querySelector(".back-to-top");
//     if (backToTop !== null) {
//       if (
//         document.body.scrollTop > 50 ||
//         document.documentElement.scrollTop > 50
//       ) {
//         backToTop.style.display = "flex";
//       } else {
//         backToTop.style.display = "none";
//       }
//     }
//   };


//   // document.addEventListener("DOMContentLoaded", () => {
//   //   const navbarToggler = document.querySelector(".navbar-toggler");
//   //   const navbarCollapse = document.querySelector(".navbar-collapse");
//   //   const udMenuScrollLinks = document.querySelectorAll(".ud-menu-scroll");
//   //   const submenuButtons = document.querySelectorAll(".nav-item-has-children");
  
//   //   udMenuScrollLinks.forEach(link => {
//   //     link.addEventListener("click", () => {
//   //       navbarToggler.classList.remove("active");
//   //       navbarCollapse.classList.remove("show");
//   //     });
//   //   });
  
//   //   navbarToggler.addEventListener("click", () => {
//   //     navbarToggler.classList.toggle("active");
//   //     navbarCollapse.classList.toggle("show");
//   //   });
  
//   //   submenuButtons.forEach(button => {
//   //     button.querySelector("a").addEventListener("click", () => {
//   //       button.querySelector(".ud-submenu").classList.toggle("show");
//   //     });
//   //   });
//   // });
  
  


//   // ===== wow js
//   // new WOW().init();


//   // ====== scroll top js



//   Math.easeInOutQuad = function (t, b, c, d) {
//     t /= d / 2;
//     if (t < 1) return (c / 2) * t * t + b;
//     t--;
//     return (-c / 2) * (t * (t - 2) - 1) + b;
//   };


//   // document.querySelector(".back-to-top").onclick = () => {
//   //   scrollTo(document.documentElement);
//   // };
// })();

