import React, { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import brand from '../../public/assets/images/logo/logo_hhoa_hori.png'
import "../js/main.js";
import BlogLink from "./BlogLink.js";
// import'../js

function Header() {
  const { country } = useParams();
  const location = useLocation();

   const handleRedirect = () => {
    const isSmallScreen = window.innerWidth < 600; // Adjust the threshold based on your design

    if (isSmallScreen) {
      const isIOS = /iPhone|iPad|iPod/.test(navigator.userAgent);
      const isAndroid = /Android/.test(navigator.userAgent);

      // Redirect to the app if it's installed
      if (isIOS) {
        window.open('https://apps.apple.com/us/app/hybridhoa/id6444210368', '_blank');
      } else if (isAndroid) {
        window.open('https://play.google.com/store/apps/details?id=com.hybridhoa&pcampaignid=web_share', '_blank');

      }
    } else {
      // Redirect to the specified URL for large or medium screens
      window.open('https://user.hybridhoa.com/#/user-portal/login', '_blank');
    }
  }

  useEffect(() => {
    const navbarToggler = document.querySelector(".navbar-toggler");
    const navbarCollapse = document.querySelector(".navbar-collapse");
    const udMenuScrollLinks = document.querySelectorAll(".ud-menu-scroll");
    const submenuButtons = document.querySelectorAll(".nav-item-has-children");

    udMenuScrollLinks.forEach((link) => {
      link.addEventListener("click", () => {
        navbarToggler.classList.remove("active");
        navbarCollapse.classList.remove("show");
      });
    });

    navbarToggler.addEventListener("click", () => {
      navbarToggler.classList.toggle("active");
      navbarCollapse.classList.toggle("show");
    });

    submenuButtons.forEach((button) => {
      button.querySelector("a").addEventListener("click", () => {
        button.querySelector(".ud-submenu").classList.toggle("show");
      });
    });

    // Remove event listeners on unmount
    return () => {
      udMenuScrollLinks.forEach((link) => {
        link.removeEventListener("click", () => {
          navbarToggler.classList.remove("active");
          navbarCollapse.classList.remove("show");
        });
      });

      navbarToggler.removeEventListener("click", () => {
        navbarToggler.classList.toggle("active");
        navbarCollapse.classList.toggle("show");
      });

      submenuButtons.forEach((button) => {
        button.querySelector("a").removeEventListener("click", () => {
          button.querySelector(".ud-submenu").classList.toggle("show");
        });
      });
    };
  }, []); // Run only once on mount

  // const  isUsRoute = location.pathname.startsWith('/us');
  // const isInRoute=location.pathname.startsWith('/in');

  // const blogUrl = isUsRoute ? 'https://blog.hybridhoa.com/us' : isInRoute ? 'https://blog.hybridhoa.com/in' : '';

  return (
    <div>
      <header className="ud-header">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <nav className="navbar navbar-expand-lg">
                {/* <Link to={isUsRoute ? '/us' : isInRoute ? '/in' : ''} className="navbar-brand"> */}
                <Link to={"/"} className="navbar-brand">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/HHOA_logo2.png `}
                    // alt={isUsRoute ? 'HHOA_logo2.png' : isInRoute ? 'HHOA_logo4.png' : ''}
                    // title={isUsRoute ? 'HHOA_logo2.png' : isInRoute ? 'HHOA_logo4.png' : ''}
                    alt={"HHOA_logo2.png"}
                    title={"HHOA_logo2.png"}
                    loading="eager"
                    width={"actualWidth"}
                    height={"actualHeight"}
                  />
                </Link>
                <button className="navbar-toggler">
                  <span className="toggler-icon"> </span>
                  <span className="toggler-icon"> </span>
                  <span className="toggler-icon"> </span>
                </button>

                <div className="navbar-collapse">
                  <ul id="nav" className="navbar-nav mx-auto">
                    <li className="nav-item">
                      <Link to={"/"} className="ud-menu-scroll">
                        Home
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link to={"/about"} className="ud-menu-scroll" replace>
                        About
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/pricing"} className="ud-menu-scroll" replace>
                        Pricing
                      </Link>
                    </li>
                    <li className="nav-item ">
                      <Link
                        to={"/contactus"}
                        className="ud-menu-scroll"
                        replace
                      >
                        ContactUs
                      </Link>
                    </li>
                    <li
                      className="nav-item nav-item-has-children"
                      style={{ marginRight: 20 }}
                    >
                      <a href="javascript:void(0)"> More </a>
                      <ul className="ud-submenu">
                        <li className="ud-submenu-item">
                          <Link
                            to={"/featuresdetail"}
                            className="ud-submenu-link"
                            replace
                          >
                            Features
                          </Link>
                        </li>
                        <li className="ud-submenu-item">
                          <Link
                            to="https://blog.hybridhoa.com/"
                            className="ud-submenu-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Blog
                          </Link>
                          {/* <BlogLink to ="https://blog.hybridhoa.com/"  className="ud-submenu-item" >
  Blog
</BlogLink> */}
                        </li>
                        <li className="ud-submenu-item">
                          <Link
                            to={"/pricing"}
                            className="ud-submenu-link"
                            replace
                          >
                            Pricing
                          </Link>
                        </li>
                        <li className="ud-submenu-item">
                          <Link
                            to={"/howitwork"}
                            className="ud-submenu-link"
                            replace
                          >
                            How it works
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <div style={{ width: "100%" }}></div>
                    <li
                      className="nav-item nav-item-has-children"
                      style={{ marginLeft: "40px" }}
                    >
                      <a href="javascript:void(0)"> Login</a>
                      <ul className="ud-submenu">
                        <li className="ud-submenu-item">
                          <a
                            href="https://group.hybridhoa.com/#/group-portal/"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ud-submenu-link"
                          >
                            Admin Login
                          </a>
                        </li>
                        <li className="ud-submenu-item">
                          <a
                            // href="https://user.hybridhoa.com/#/user-portal/login"
                            // href="#"
                            onClick={handleRedirect}
                            // target="_blank"
                            // rel="noopener noreferrer"
                            className="ud-submenu-link"
                          >
                            Home Owner Login
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="pt-3">
                      <a
                        className="ud-main-btn ud-white-btn ud-main-btns"
                        target="_blank"
                        href="https://calendly.com/hybridhoa"
                        style={{
                          backgroundColor: "white",
                          color: "#2748b4",
                          marginLeft: 50,
                        }}
                      >
                        Schedule a Demo
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="navbar-btn  d-sm-inline-block">
                  <div className='row'>
                    <div className='col-lg-6 col-sm-6'>
                    <ul id="nav" className="navbar-nav mx-auto">
                  <li className="nav-item nav-item-has-children" >
                    <a href="javascript:void(0)">Login </a>
                    <ul className="ud-submenu">
                           <li className="ud-submenu-item">
                        <a href="https://group.hybridhoa.com/#/group-portal/" 
                        target="_blank" rel="noopener noreferrer" className="ud-submenu-link">
                           Admin Login
                        </a>
                      </li>
                      <li className="ud-submenu-item">
                        <a href="https://user.hybridhoa.com/#/user-portal/login" 
                        target="_blank" rel="noopener noreferrer" className="ud-submenu-link">
                         Home Owner Login
                        </a>
                      </li>
                      </ul>
                      </li>
                  </ul>

                    </div>
                    <div className='col-lg-6 col-sm-6 pt-3'>
                    <a className="ud-main-btn ud-white-btn" target="_blank" href="https://calendly.com/hybridhoa" style={{backgroundColor:'white',color:'#2748b4'}}>
                 Schedule  Demo
                </a>

                    </div>
                  </div>
                 
              
              </div>   */}
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default Header;
