import React from 'react';

class BlogLink extends React.Component {
  openedWindow = null;

  handleClick = (event) => {
    event.preventDefault();
    const { to } = this.props;

    // Check if the tab is already open
    if (this.openedWindow && !this.openedWindow.closed) {
      this.openedWindow.focus();
    } else {
      // Use replaceState to remove the referrer information
      window.history.replaceState(null, document.title, window.location.href);

      // Store a flag in sessionStorage to indicate that the new tab has been opened
      sessionStorage.setItem('newTabOpened', 'true');

      // Open the link in a new tab
      this.openedWindow = window.open(to, '_blank');
    }
  };

  componentWillUnmount() {
    // Close the opened window when the component is unmounted
    if (this.openedWindow) {
      this.openedWindow.close();
    }
  }

  render() {
    const { to, className, children } = this.props;

    return (
      <li className={className}>
        <a href={to} className="ud-submenu-link"  rel="noopener noreferrer">
          {children}
        </a>
      </li>
    );
  }
}

export default BlogLink;
