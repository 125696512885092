
import React from 'react'

function PageHeader(props) {
  return (
    <div>
      <section className="ud-page-banner">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ud-banner-content">
              <h1>{props.title}</h1>
            </div>
          </div>
        
        </div>
      </div>
    </section>
    </div>
  )
}

export default PageHeader
