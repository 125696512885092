import React, { useCallback, useEffect, useState } from "react";
import Header from "../Components/Header";
import SeparatePageHeader from "../Components/SeparatePageHeader";
import { BASE_URL, DATACOLLECTIONANDSECURITY } from "../utils/ApplicationEndpoints";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import Footer from "../Components/Footer";

function DataCollectionAndSecurity() {
  const [privacyPolicy, setPrivacyPolicy] = useState([]);


  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + DATACOLLECTIONANDSECURITY);
      setPrivacyPolicy(response.data[0].data);

      console.log("testtttttttaishuuuuuuuuuu" + response.data[0].data);
    } catch (error) {
      console.error("Error fetching Privacy Policy data:", error);
    }
  }, []);

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname.startsWith('/')) {
      fetchData(0);
    } 
    // You can add more conditions for different URLs if needed.
  }, [fetchData]);

  const location = useLocation();

  useEffect(() => {
    // Scroll to the top when the location changes
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <div>
      <Helmet>
        <title>Data Collection And Security</title>
        <meta name="title" content="Data Collection And Security" />
        <meta
          name="description"
          content="Personal data is identifiable info about a person. Excludes anonymous, de-identified, or aggregated info. 'Personal data' and 'personal info' are synonymous."
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.hybridhoa.com/datacollectionandsecurity"
        />
        <meta property="og:title" content="Data Collection And Security" />
        <meta
          property="og:description"
          content="Personal data is identifiable info about a person. Excludes anonymous, de-identified, or aggregated info. 'Personal data' and 'personal info' are synonymous."
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/datacollectionandsecurity"
        />
        <meta property="twitter:title" content="Data Collection And Security" />
        <meta
          property="twitter:description"
          content="Personal data is identifiable info about a person. Excludes anonymous, de-identified, or aggregated info. 'Personal data' and 'personal info' are synonymous."
        />
          <meta property="og:image" content="../assets/img/preview.png" />
        {/* modified by mohanraj 25-08-23 start */}
<link rel='canonical' href='https://www.hybridhoa.com/datacollectionandsecurity' />
<meta name="viewport" content="width=device-width,initial-scale=1"></meta>
        {/* modified by mohanraj 25-08-23 end */}


      </Helmet>
      <Header />
      <SeparatePageHeader title="Data Collection & Security" />
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6"></div>
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div dangerouslySetInnerHTML={{ __html: privacyPolicy }}></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DataCollectionAndSecurity;
