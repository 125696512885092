import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import PageHeader from "../Components/PageHeader";
import Header from "../Components/Header";
import {
  BASE_URL,
  FEATURE_CATEGORY,
  FEATURE_CARD,
  IMAGE_FEATURE,
} from "../utils/ApplicationEndpoints";
import { Helmet } from "react-helmet";
import hybridhoa from "../Images/Hybridhoalogo.png";
import HomeFeatureHeader from "../Components/HomeFeatureHeader";
import frame from "../Images/Frame.png";
import { data } from "jquery";

function Feature() {
  const [data, setData] = useState([]);
  const [FeatureCarddata, setFeatureCarddata] = useState([]);

  const [selectedMessage, setSelectedMessage] = useState("");

  const [shouldScroll, setShouldScroll] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(BASE_URL + FEATURE_CATEGORY);
      setData(response.data);
      console.log(response.data);
      console.log(response.data[0].title);
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchDataCard = useCallback(async () => {
    try {
      const responsecard = await axios.get(BASE_URL + FEATURE_CARD);

      setFeatureCarddata(responsecard.data);
      console.log(responsecard.data);
      const categoryCounts = {};

      // Iterate through the featureData and count items for each category
      FeatureCarddata.forEach((item) => {
        const category = item.category;
        categoryCounts[category] = (categoryCounts[category] || 0) + 1;
      });

      // Print the counts with category names in the console
      Object.entries(categoryCounts).forEach(([category, count]) => {
        console.log(`Category: ${category}, Count: ${count}`);
      });
    } catch (error) {
      console.error("Error fetching feature data:", error);
    }
  }, []);

  useEffect(() => {
    fetchDataCard();
  }, [fetchDataCard]);

  useEffect(() => {
    if (shouldScroll) {
      const scrollToTarget = () => {
        const targetElement = document.getElementById("scrollTarget");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };
      scrollToTarget();
      setShouldScroll(false);
    }
  }, [shouldScroll]);

  useEffect(() => {
    if (data.length > 0) {
      setSelectedMessage(data[0].id);
    }
  }, [data]);

  const myStyle = {
    listStyle: "none",
    padding: 0,
  };

  const listItemStyle = {
    position: "relative",
    paddingLeft: "40px",
    marginBottom: "10px",
    marginLeft: "150px",
    height: "auto",
    width: "auto",
    fontSize: "20px",
  };

  const iconStyle = {
    content: `url(${hybridhoa})`,
    position: "absolute",
    left: "0",
    top: "50%",
    transform: "translateY(-50%)",
    width: "20px",
    height: "20px",
  };

  const handleClick = (id) => {
    setSelectedMessage(id);
    console.log(`Clicked on: ${id}`);
  };

  // const handleClick = (message) => {
  //   setSelectedMessage(message);
  // };

  const getSpanStyle = (id) => {
    const commonStyle = {
      cursor: "pointer",
      fontSize: "13px",
    };
    return {
      ...commonStyle,
      color: selectedMessage === id ? "blue" : "black",
      fontWeight: selectedMessage === id ? "bold" : "normal",
      textDecoration: selectedMessage === id ? "underline" : "none",
      // Additional styles based on your logic
    };
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Features</title>
        <meta name="title" content="Features" />
        <meta
          name="description"
          content="Efficiently manage your community with our HybridHOA features like virtual manager,ARC, accounting, polling and more"
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.hybridhoa.com/featuresdetail"
        />
        <meta property="og:title" content="Features" />
        <meta
          property="og:description"
          content="Efficiently manage your community with our HybridHOA features like virtual manager,ARC, accounting, polling and more"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://www.hybridhoa.com/featuresdetail"
        />
        <meta property="twitter:title" content="Features" />
        <meta
          property="twitter:description"
          content="Efficiently manage your community with our HybridHOA features like virtual manager,ARC, accounting, polling and more"
        />
        <meta property="og:image" content="../assets/img/preview.png" />
        {/* modified by mohanraj 25-08-23 start */}
        <link rel="canonical" href="https://www.hybridhoa.com/featuresdetail" />

        <meta name="viewport" content="width=device-width,initial-scale=1" />

        {/* modified by mohanraj 25-08-23 end */}
      </Helmet>
      <Header />
      {/* <PageHeader title="Our features that put you ahead of the game" /> */}
      <HomeFeatureHeader title="Our features that put you ahead of the game" />

      <div className="text-center">
        <div
          className="d-none d-sm-block"
          onClick={() => setShouldScroll(true)}
        >
          <span className="icon flex-shrink-0">
            <i
              className="lni lni-chevron-down-circle"
              style={{
                fontSize: "30px",
                color: "blue",
                marginTop: "10px",
                cursor: "pointer",
              }}
            ></i>
          </span>
        </div>
      </div>

      <div className="d-flex justify-content-center" id="scrollTarget">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div className="d-none d-sm-block">
              <img
                src={frame}
                alt="frame Image"
                style={{
                  position: "relative",
                  width: "60px",
                  height: "60px",
                  marginLeft: "900px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div className="row">
          {data.map((item) => (
            <div
              className="col-lg-3 col-md-6 col-sm-12 text-center"
              key={item.id}
            >
              <div className="d-none d-sm-block">
                <div
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    margin: "10px",
                    minWidth: "100px",
                    backgroundColor: "#E7FCFD",
                    borderRadius: "100px",
                    width: "130px",
                    height: "130px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => handleClick(item.id)}
                >
                  <i
                    className={`lni ${item.icon}`}
                    onClick={() => handleClick(item.id)}
                    style={{ cursor: "pointer", fontSize: "70px" }}
                  ></i>
                </div>
              </div>
              <div>
                <span
                  onClick={() => handleClick(item.id)}
                  style={{ ...getSpanStyle(item.id), fontSize: "13px" }}
                >
                  {item.title}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="d-flex justify-content-center">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div className="d-none d-sm-block">
              <img
                src={frame}
                alt="frame Image"
                style={{
                  position: "relative",
                  width: "60px",
                  height: "60px",
                  marginRight: "900px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* ----------------------------------Card -------------------------------------------------- */}

      {FeatureCarddata.filter(
        (feature) => parseInt(selectedMessage) === parseInt(feature.category)
      ).map((feature, index) => (
        <div
          key={index}
          className={`card mb-6 ${index % 2 === 0 ? "even" : "odd"}`}
          style={{
            maxWidth: "1300px",
            margin: "25px auto",
            backgroundColor: index % 2 === 0 ? "#F9F9F9" : "#FFFFFF",
            border: "none",
            borderRadius: "30px",
          }}
        >
          <div className="row g-0">
            {index % 2 === 0 ? (
              <>
                <div className="col-md-8 col-sm-12">
                  <div className="card-body">
                    <h5 className="card-title" style={{ marginLeft: "125px" }}>
                      {feature.topic}
                    </h5>
                    <br />
                    <ul style={myStyle}>
                      {feature.descriptionList.map((description, descIndex) => (
                        <li key={descIndex} style={{ ...listItemStyle }}>
                          <span style={iconStyle}></span>
                          {description.content}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <img
                    src={BASE_URL + IMAGE_FEATURE + `${feature.imageUrl}`}
                    style={{
                      width: "300px",
                      height: "250px",
                      marginTop: "10px",
                    }}
                    className="img-fluid rounded-start"
                    alt={feature.imgAlternative}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="col-md-4 col-sm-12">
                  <img
                    src={BASE_URL + IMAGE_FEATURE + `${feature.imageUrl}`}
                    style={{
                      width: "300px",
                      height: "250px",
                      marginTop: "10px",
                      marginLeft: "100px",
                    }}
                    className="img-fluid rounded-start"
                    alt={feature.imgAlternative}
                  />
                </div>
                <div className="col-md-8 col-sm-12">
                  <div className="card-body">
                    <h5 className="card-title" style={{ marginLeft: "125px" }}>
                      {feature.topic}
                    </h5>
                    <br />
                    <ul style={myStyle}>
                      {feature.descriptionList.map((description, descIndex) => (
                        <li key={descIndex} style={listItemStyle}>
                          <span style={iconStyle}></span>
                          {description.content}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ))}

      <br />
    </React.Fragment>
  );
}

export default Feature;
